import axios from 'axios';
import { message } from 'antd';

const url = process.env.REACT_APP_API_KEY;

export const fetchProfile = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}profile`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const updateProfile = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}profile`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const updatePassword = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}profile/change/password`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchAddresses = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}addresses`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchStates = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}list/countries`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const addAddress = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}addresses`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const updateAddress = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}addresses/update`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const selectAddress = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}cart/address/select`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const deleteAddress = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}addresses/delete`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const CancelOrder = (id, body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}orders/cancel/${id}`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const Reorder = (id, body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}orders/reorder/${id}`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const ShowOrderDetails = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}order/show?orderId=${body}`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const ListMyOrders = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}orders`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchFavoriteProducts = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}favorite/products`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const ListTicket = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}supportTickets`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const ListTicketTypes = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
   //  if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}supportType/list`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const SendTicket = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
    deviceId: localStorage.getItem('deviceId'),
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  axios({
    method: 'post',
    url: `${url}supportTicket/send`,
    data: body,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};
