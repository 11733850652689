import axios from "axios";
import { message } from "antd";

const url = process.env.REACT_APP_API_KEY;

// headers: {'Content-Type': 'multipart/form-data;'}

export const userLogin = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
      "deviceId": localStorage.getItem("deviceId"),  
    };
    // if(localStorage.getItem("token")){
    //   headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    // }
    axios({    
        method: 'post',
        url: `${url}login`,
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
                // message.error('اسم مستخدم أو كلمة مرور غير صالحة');
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const userLoginFb = (body, onSucess, onFail) => {
        const headers = {
          "Content-Type": "application/json",
          "content-language": `${localStorage.getItem("lang") || "en"}`,  
          "deviceId": localStorage.getItem("deviceId"),
        };
    // if(localStorage.getItem("token")){
    //   headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    // }
    // if(localStorage.getItem("deviceId")){
    //   if(!!!localStorage.getItem("token")){
    //     headers.deviceId = localStorage.getItem("deviceId") 
    //   }
    // }
    axios({ 
        method: 'post',
        url: `${url}login/provider`,
        data: body, 
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const userRegister = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
      "deviceId": localStorage.getItem("deviceId"),
    };
    // if(localStorage.getItem("token")){
    //   headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    // }
    // if(localStorage.getItem("deviceId")){
    //   if(!!!localStorage.getItem("token")){
    //     headers.deviceId = localStorage.getItem("deviceId") 
    //   }
    // } 
    axios({ 
        method: 'post',
        url: `${url}register`,
        data: body,
        headers 
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const verifyCode = (body, onSucess, onFail) => {
        const headers = {
          "Content-Type": "application/json",
          "content-language": `${localStorage.getItem("lang") || "en"}`,  
          
        };
      if(localStorage.getItem("token")){
        headers.Authorization = `Bearer ${localStorage.getItem("token")}`
      }
    if(localStorage.getItem("deviceId")){   
      if(!!!localStorage.getItem("token")){
        headers.deviceId =  `${localStorage.getItem("deviceId")}`;  
      }  
    } 
    axios({ 
        method: 'post',
        url: `${url}verify/registration/code`, 
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const forgetPassword = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId"); 
      }
    }
    axios({ 
        method: 'post',
        url: `${url}forgot/password`, 
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const handelResetPassword = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({ 
        method: 'post',
        url: `${url}reset/forgot/password`, 
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const resendVerificationCode = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId")
      }
    }
    axios({ 
        method: 'post',
        url: `${url}resend/code`, 
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}

export const ContactUs = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId")
      }
    }
    axios({ 
        method: 'post',
        url: `${url}contact`, 
        data: body,
        headers
        })
        .then((response) => {
            if (response.data.validation.length > 0) { 
                response.data.validation.forEach(element => {
                    message.error(element); 
                });
            }
            return onSucess(response);
        }) 
        .catch(err => onFail(err)); 
}
