import React, { Component } from 'react';
import { ListMyOrders } from '../../network/profile.network';

import './MyOrder.scss';
import { Spin, Result, Button } from 'antd';
import {  withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CancellationReasons from '../CancellationReasons/CancellationReasons';
import { fetchMyOrders } from '../../redux/presistData/presistData.action';
import OrderAlternative from '../OrderAlternative/OrderAlternative';
import { Link as I18Link } from 'react-router-i18n';

class MyOrder extends Component {
  state = {
    isLoading: false,
    showOrderDetails: false,
    showReasonsModal: false,
    orderList: null,
    orderTracking: null,
    originalOrder: null,
    alternativeOrder: null,
    isOrderAlt: false,
  };

  componentDidMount() {
    const { presistData } = this.props;

    if (presistData === null) {
      this.setState({ isLoading: true });
      this.presistMyOrder();
    } else {
      this.setState({ orderList: presistData });
      this.presistMyOrder();
    }
  }

  presistMyOrder = () => {
    ListMyOrders(
      (res) => {
        this.setState({ isLoading: false });
        this.props.dispatch(fetchMyOrders(res.data.data));
        this.setState({ orderList: res.data.data });
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };

  showOrderList = () => {
    this.setState({ orderTracking: null, showOrderDetails: false });
  };

  handelCancelOrder = (orderId) => {
    this.setState({ cancelorderId: orderId, showReasonsModal: true });
  };

  closeCancelOrderModal = () => {
    this.setState({ showReasonsModal: false });
  };

  showAllorder = () => {
    this.setState({isOrderAlt : false}, () => {
      this.presistMyOrder();
    })
  }

  render() {
    const {
      orderList,
      alternativeOrder,
      isLoading,
      showOrderDetails,
      showReasonsModal,
      cancelorderId,
      isOrderAlt,
    } = this.state;

    const { localization, currentLang } = this.props;

    return (
      <>
        <Spin spinning={isLoading}>
          {isOrderAlt ? (
              <OrderAlternative showAllorder={this.showAllorder} alternativeOrder={alternativeOrder} /> 
          ) : (
            <div className="user-order-wrapper">
              {orderList?.oldOrders?.length === 0 &&
              orderList?.pendingOrders?.length === 0 ? (
                <Result
                  status="404"
                  title=""
                  subTitle={localization.sorrynoorder[currentLang]}
                  extra={
                    <Button type="primary" size="large">
                      <I18Link to={{ pathname: '/' }}>{localization.BackToHome[currentLang]}</I18Link>
                    </Button>
                  }
                />
              ) : (
                <>
                  <CancellationReasons
                    closeModal={() =>{
                      this.setState({ showReasonsModal: false });
                      this.presistMyOrder();
                    }
                    }
                    showModalVisible={showReasonsModal}
                    orderId={cancelorderId}
                    currentLang={currentLang}
                    localization={localization}
                    fromOrderList
                  />
                  <div className="user-order-type">
                    <div className="user-order--header">
                      {orderList?.pendingOrders?.length > 0 && (
                        <p>{localization.Onprocessorders[currentLang]}</p>
                      )}
                    </div>
                    {orderList?.pendingOrders?.map((order) => (
                      <I18Link
                        to={{ pathname : `/order-info/${order.id}`}}
                        className="process-order-card"
                        style={{ borderColor: `${order.status.color}` }}
                        key={order.id}
                      >
                        <div className="process-order-info">
                          <div className="process-order-number">
                            <p className="order-title">
                              {localization.OrderID[currentLang]} :
                            </p>
                            <p className="order-num">#{order.id}</p>
                            <p
                              className="order-status"
                              style={{ color: `${order.status.color}` }}
                            >
                              - {order.status.name}
                            </p>
                          </div>
                          <time>{order.createdAt}</time>
                        </div>
                        {order.status.id !== 3 && (
                          <div className="process-order-action">
                            {order.hasAlternatives ? 
                                <p
                                className="cancel-order alternative-order-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  this.setState({isOrderAlt : true, alternativeOrder :order })
                                }}
                              >
                                {localization.showalternatives[currentLang]}
                              </p> :
                            <p
                              className="cancel-order"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handelCancelOrder(order.id);
                              }}
                            >
                              {localization.Cancelorder[currentLang]}
                            </p>
                          }

                          </div>
                        )}
                      </I18Link>
                    ))}
                  </div>

                  {
                    <div className="user-order-type user-order-type-prev">
                      {orderList?.oldOrders?.length > 0 && (
                        <p className="old-order-tilte">
                          {localization.YourPreviousorders[currentLang]}
                        </p>
                      )}
                      {orderList?.oldOrders?.length > 0 &&
                        orderList?.oldOrders?.map((order) => (
                          <I18Link
                            to={{
                              pathname: `/order-info/${order.id}`,
                              query: { isOld: true }, 
                            }}
                            className="process-order-card"
                            key={order.id}
                          >
                            <div className="process-order-info">
                              <div className="process-order-number">
                                <p className="order-title">
                                  {localization.OrderID[currentLang]} :
                                </p>
                                <p className="order-num">#{order.id}</p>
                                {order.cancelled ? 
                                <p
                                  className="order-status"
                                  style={{ color: `#f82727` }}
                                >
                                  - {localization.CancelledOrder[currentLang]} 
                                </p> : 
                                <p
                                className="order-status"
                                style={{ color: `#6cc04a` }}
                              >
                                - {localization.Completed[currentLang]}
                              </p>
                                }
                              </div>
                              <time>{order.createdAt}</time>
                            </div>
                          </I18Link>
                        ))}
                    </div>
                  }
                </>
              )}
            </div>
          )}
        </Spin>
      </>
    );
  }
}

const MyOrderWithRouter = withRouter(MyOrder);
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
  presistData: state.presistData.presistMyOrders,
});

export default connect(mapStateToProps)(MyOrderWithRouter);
