import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import cartReducer from './cart/cart.reducer';
import productsReducer from './products/products.reducer';
import presistReducer from './presistData/presistData.reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'products', 'user', 'homeDetails'],
};

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  products: productsReducer,
  presistData: presistReducer,
});

export default persistReducer(persistConfig, rootReducer);
