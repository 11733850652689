import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Category from './pages/Category/Category';
import Register from './pages/Register/Register';
import PhoneVerify from './pages/PhoneVerify/PhoneVerify';
import NoMatch from './pages/NoMatch/NoMatch';
import Cart from './pages/Cart/Cart';
import SingleProduct from './pages/SingleProduct/SingleProduct';
import Profile from './pages/Profile/Profile';
import Checkout from './pages/Checkout/Checkout';
import OrderDone from './pages/OrderDone/OrderDone';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import PasswordThank from './pages/PasswordThank/PasswordThank';
import Recipes from './pages/Recipes/Recipes';
import SingleRecipes from './pages/SingleRecipes/SingleRecipes';
import SingleOrder from './pages/SingleOrder/SingleOrder';
import FAQS from './pages/FAQS/FAQS';
import Terms from './pages/terms/Terms';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';

const base = '/:locale(en|ar)?';

class Routes extends Component {
  render() {
    return (
      <>
        <Route exact path={base} render={() => <Home />} />

        <Route exact path={`${base}/login`} render={() => <Login />} />
        <Route exact path={`${base}/register`} render={() => <Register />} />
        <Route
          exact
          path={`${base}/forget-password`}
          render={() => <ForgetPassword />}
        />
        <Route exact path={`${base}/reset-password`} render={() => <ResetPassword />} />
        <Route
          exact
          path={`${base}/phone-verification`}
          render={() => <PhoneVerify />}
        />
        <Route
          exact
          path={`${base}/password-changed`}
          render={() => <PasswordThank />}
        />

        <Route exact path={`${base}/profile`} render={() => <Profile />} />
        <Route
          exact
          path={`${base}/profile/:id/:name`}
          render={() => <Profile />}
        />

        <Route exact path={`${base}/recipes`} render={() => <Recipes />} />
        <Route
          exact
          path={`${base}/recipes/:id`}
          render={() => <SingleRecipes />}
        />
        <Route
          exact
          path={`${base}/recipe/:id/:name`}
          render={() => <SingleRecipes />}
        />
        <Route
          exact
          path={`${base}/product/:id/:name`}
          render={() => <SingleProduct />}
        />
        <Route
          exact
          path={`${base}/category/:lvlId/:id/:name`}
          render={() => <Category />}
        />
        <Route exact path={`${base}/deals`} render={() => <Category />} />
        <Route exact path={`${base}/cart`} render={() => <Cart />} />
        <Route exact path={`${base}/checkout`} render={() => <Checkout />} />
        <Route exact path={`${base}/contact-us`} render={() => <Contact />} />
        <Route
          exact
          path={`${base}/order-placed`}
          render={() => <OrderDone />}
        />
        <Route
          exact
          path={`${base}/FAQs`}
          render={() => <FAQS />}
        />
        <Route
          exact
          path={`${base}/about`} 
          render={() => <About />}
        />
        <Route
          exact
          path={`${base}/terms-and-conditions`}
          render={() => <Terms />}
        />
        <Route
          exact
          path={`${base}/order-info/:id`}
          render={() => <SingleOrder />}
        />
        <Route
          exact
          path={`${base}/brand/:id/:name`}
          render={() => <Category />}
        />
        <Route
          exact
          path={`${base}/search/:name`}
          render={() => <Category />}
        />

        <Route exact path={`${base}/404`} component={NoMatch} />
      </>
    );
  }
}

export default Routes;
