import { message } from 'antd';
import axios from 'axios';
const url = process.env.REACT_APP_API_KEY;

export const fetchHome = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}home?web`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          // message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchOffers = (body = false, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}offers${body ? body : ''}`,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const fetchOffersType = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}offer/type/products`,
    data: body,
    // headers: myHeaders,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

export const addUserBranch = (body, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'post',
    url: `${url}branches/select`,
    data: body,
    // headers: myHeaders,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

// Fetch Recipes
export const fetchRecipes = (onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}recipes`,
    // data: body,
    // headers: myHeaders,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

// Fetch Single Recipe
export const fetchSingleRecipe = (id, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}recipes/${id}`,
    // data: body,
    // headers: myHeaders,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};

// Fetch Single Order
export const fetchSingleOrder = (id, onSucess, onFail) => {
  const headers = {
    'Content-Type': 'application/json',
    'content-language': `${localStorage.getItem('lang') || 'en'}`,
  };
  if (localStorage.getItem('token')) {
    headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  if (localStorage.getItem('deviceId')) {
    // if (!!!localStorage.getItem('token')) {
      headers.deviceId = localStorage.getItem('deviceId');
    // }
  }
  axios({
    method: 'get',
    url: `${url}orders/${id}`,
    // data: body,
    // headers: myHeaders,
    headers,
  })
    .then((response) => {
      if (response.data.validation.length > 0) {
        response.data.validation.forEach((element) => {
          message.error(element);
        });
      }
      return onSucess(response);
    })
    .catch((err) => onFail(err));
};
