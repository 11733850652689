import { message, Radio, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPaymentList, SelectPayment } from "../../network/cart.network";
import { setPaymentUser } from "../../redux/user/user.action";

class SelectPayMethod extends Component {
  state = {
    showModal: false,
    confirmLoading: false,
    value: 0,
    PaymentList : []
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.handelFetchPaymentList();
  }

  handelFetchPaymentList = () => {
    fetchPaymentList((res) => {
      this.setState({PaymentList : res.data.data.paymentTypes})
    }, (error) => {
      console.log(error); 
    })
  }
  

  componentDidUpdate(prevProps) {
    const {currentLang, showModalVisible} = this.props;
    if (showModalVisible !== this.state.showModal) {
      this.setState({ showModal: showModalVisible });
    }
    if(currentLang !== prevProps.currentLang){
      this.handelFetchPaymentList();
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handelSelectPayment = () => {
    const { value } = this.state;
    this.setState({ confirmLoading: true });
    let body = new FormData();
    body.append("paymentTypeId", +value); 
    SelectPayment( 
      body,
      (res) => {
        this.setState({ confirmLoading: false});
        this.props.closeModal(); 
        if (res.data.code === 200) {
          message.success(res.data.message);
          const selectPay = this.state.PaymentList.find(ele => ele.id === value);
          this.props.dispatch(setPaymentUser(selectPay)) 
          this.props.setPaymentCallBack(selectPay);  
        }
      },
      (error) => {
        this.setState({ confirmLoading: false });
        console.log(error); 
      }
    );
  };

  render() {
    const { showModal, value, confirmLoading, PaymentList } = this.state;
    const { currentLang, localization } = this.props;
    return (
      <div className="pay-modal-wrapper">
        <Modal
          wrapClassName="pay-modal"
          onCancel={this.props.closeModal}
          title="" 
          okText={localization.Save[currentLang]} 
          cancelText={localization.Cancel[currentLang]} 
          onOk={this.handelSelectPayment}
        //   onCancel={() => console.log('object') }
          confirmLoading={confirmLoading}
          visible={showModal}
        >
          <Radio.Group onChange={this.onChange} value={value}>
            <Space direction="vertical" size="middle"> 
              {PaymentList.map(type => 
                  <Radio value={type.id} key={type.id}>{type.type}</Radio>
              )}
            </Space>
          </Radio.Group>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(SelectPayMethod); 
