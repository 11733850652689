import {
  ADD_PRODUCT,
  ITEMS_LIST,
  INCRESE_PRODUCT,
  EDIT_PRODUCT,
  SET_FILTERD_LIST,
  CLEAR_CART,
  REMOVE_PRODUCT,
} from "./cart.type";

const INITIAL_STATE = {
  cartItems: [],
  itemsList: [],
};
const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        cartItems: [action.payload, ...state.cartItems],
      };
    case REMOVE_PRODUCT:
      return {
        ...state,
        cartItems: [...state.cartItems].filter(
          (product) => product.id !== action.payload
        ),
      };
      
    case EDIT_PRODUCT:
      return handelEditItem(state, action.payload);

    case INCRESE_PRODUCT:
      return {
        ...state,
        itemsList: action.payload,
      };
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
    case ITEMS_LIST:
      return {
        ...state,
        itemsList: action.payload,
      };
    case SET_FILTERD_LIST:
      return {
        ...state,
        itemsList: action.payload,
      };
    default:
      return state;
  }
};

function handelEditItem(state, payload) {
  let selectedItemIndx = state.cartItems.findIndex(
    (ele) => ele.itemId === payload.itemId
  );
   state.cartItems[selectedItemIndx].inCart.quantity = +payload.quantity;
   state.cartItems[selectedItemIndx].quantity = +payload.quantity;
  return {
    ...state,
    cartItems: [...state.cartItems],
  };
}

export default cartReducer;
