import { ADD_PRODUCT, INCRESE_PRODUCT, ITEMS_LIST, EDIT_PRODUCT, SET_FILTERD_LIST, CLEAR_CART, REMOVE_PRODUCT } from "./cart.type";

export const addProductToCart= product => ({
    type : ADD_PRODUCT,
    payload : product
})

export const removeProductToCart= productId => ({
    type : REMOVE_PRODUCT,
    payload : productId
})

export const editProduct= product => ({
    type : EDIT_PRODUCT,
    payload : product
})

export const increseProduct= product => ({
    type : INCRESE_PRODUCT,
    payload : product
})

export const clearCart = () => ({
    type : CLEAR_CART,
})

export const addItemsList = itemsList => ({
    type : ITEMS_LIST,
    payload : itemsList.map(ele => ({ ...ele, quantaty : 1 }))
})

export const addFilterdList = itemsList => (
    // console.log(itemsList)
    {
    type : SET_FILTERD_LIST,
    payload : itemsList
}
)

