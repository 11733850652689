import React, { Component } from 'react'
import { Modal, Button } from 'antd';
import MyAddresses from '../MyAddresses/MyAddresses';
export default class AddressModal extends Component {

    state = {
        showModal : false,
    }

    closeModal = () => {
        this.setState({showModal : false})
    }

    componentDidUpdate(prevProps) {
        if(this.props.showModalVisible !== this.state.showModal){ 
            this.setState({showModal : this.props.showModalVisible})
        }
    }
    
 
    render() {
        const { showModal } = this.state; 
        return (
            <div className="address-modal-wrapper">
                 <Modal 
                 wrapClassName="address-modal" 
                 destroyOnClose
                 onCancel={this.props.closeModal} 
                 title="" footer={null} visible={showModal}>
                    <MyAddresses ispopup={this.props.ispopup} noSetAddressLocal={this.props.noSetAddressLocal} closeModal={this.props.closeModal} /> 
                </Modal>
            </div>
        )
    }
}
