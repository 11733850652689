import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Link as I18Link } from "react-router-i18n";
import google from "../../assets/img/google.svg";
import apple from "../../assets/img/apple.svg";
import fb from "../../assets/img/fb.svg";
import tw from "../../assets/img/tw.svg";
import huawei from "../../assets/img/huawei_badge.svg";
import ins from "../../assets/img/in.svg";
import logo from "../../assets/img/Logo.png";
import MANSOUR from "../../assets/img/MANSOUR-logo.jpg";
import MANSOURwhite from "../../assets/img/MANSOUR-logo-white.png";
import localization from "../../Localization/Localization.json";
import BranchModal from "../BranchModal/BranchModal";
import "./Footer.scss";

class Footer extends Component {
  state = {
    branchModalVisible: false,
  };
  render() {
    const { currentLang } = this.props;
    const { branchModalVisible } = this.state;
    return (
      <div className="footer-wrapper">
        <BranchModal
          fetchHomeDetails={this.props.fetchHomeDetails}
          closeModal={() => this.setState({ branchModalVisible: false })}
          branchModalVisible={branchModalVisible}
        />
        <div className="container">
          <div className="footer-content">
            <div className="links-wrapper">
              <div className="links-group logos-web-holder">
                <I18Link to={{ pathname: "/" }}>
                  <img src={logo} alt="" />
                </I18Link>
                <a className="MANSOUR-logo">
                  <img src={MANSOURwhite} alt="" />
                </a>
              </div>
              <div className="links-group">
                <h4>{localization.About[currentLang]}</h4>
                <p>{localization.about1[currentLang]}</p>
                <div className="follow-wrapper">
                  <p className="follow-title">
                    {localization.followUs[currentLang]}
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/KheirZamanEgypt/"
                        target="_blank"
                      >
                        <img src={fb} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/kheirzamanegypt/"
                        target="_blank"
                      >
                        <img src={ins} alt="" />
                      </a>
                    </li>
                    {/* <li>
                    <a href="https://twitter.com/kheirzaman" target="_blank">
                      <img src={tw} alt="" />
                    </a>
                  </li> */}
                  </ul>
                </div>
              </div>

              <div className="links-group">
                <h4>{localization.Categories[currentLang]}</h4>
                <ul>
                  <li>
                    <I18Link to={{ pathname: "/category/1/12/Commodities" }}>
                      {localization.Grocery[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/category/1/1/Fruit" }}>
                      {localization.Fruits[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/category/1/2/Vegetables" }}>
                      {localization.vegetables[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/category/1/7/Meat" }}>
                      {localization.Meat[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/category/1/8/Poultry" }}>
                      {localization.Poultry[currentLang]}
                    </I18Link>
                  </li>
                </ul>
              </div>

              <div className="links-group">
                <h4>{localization.Quicklinks[currentLang]}</h4>
                <ul>
                  {/* <li>
                  <Link to="/">{localization.privacyPolicy[currentLang]}</Link>
                </li> */}
                  <li>
                    <I18Link to={{ pathname: "/terms-and-conditions" }}>
                      {localization.TermsConditions[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/contact-us" }}>
                      {localization.Contactus[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <I18Link to={{ pathname: "/FAQs" }}>
                      {localization.FAQs[currentLang]}
                    </I18Link>
                  </li>
                  <li>
                    <a
                      onClick={() =>
                        this.setState({ branchModalVisible: true })
                      }
                    >
                      {localization.FindStore[currentLang]}
                    </a>
                  </li>
                </ul>
              </div>
              <div className="links-group">
                <h4>{localization.Downloadapp[currentLang]}</h4>
                <ul>
                  <li>
                    <Link to="/">
                      <img src={apple} alt="apple" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={google} alt="google" />
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src={huawei} alt="huawei" width="157" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="all-rights-wrraper">
              <span className="copy-r">
                &copy; {new Date().getFullYear()}.{" "}
                {localization.rights[currentLang]}
              </span>
              <span className="copy-r">
                {localization.TaxRegistration[currentLang]}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(Footer);
