import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import '../../scss/pages/auth.scss';
import { resendVerificationCode, verifyCode } from '../../network/auth.network';
import { connect } from 'react-redux';
import { setCurrentUser } from '../../redux/user/user.action';
import { codeValidation, codeValidationAr } from '../../constants/constants';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
import logo from '../../assets/img/Logo.png';
import password_resset from '../../assets/img/password_resset.svg';
import {Helmet} from "react-helmet";
 import meta from '../../Localization/meta.json';
class PasswordThank extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
      disableResetCode: false,
      tel: null,
    };
  }

  render() {
    const { btnLoading, disableResetCode } = this.state;
    const { currentLang } = this.props;
    return (
      <div>
         <Helmet>
            <title>{meta.KhairZamanPassword[currentLang]}</title>
        </Helmet>
        <div className="auth-wrapper password-change-wrapper">
          <div className="auth-form-content">
            <I18Link to={{ pathname: '/' }} className="logo-holder">
              <img src={logo} alt="logo" />
            </I18Link>
            <div className="logo-holder" style={{ boxShadow: 'none' }}>
              <img src={password_resset} alt="logo" />
            </div>
            <p className="form-welcom">{localization.Thanks[currentLang]}</p>
            <p className="login-text">
              {localization.passwordchangedsuccessfully[currentLang]}
            </p>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              block
              loading={btnLoading}
              onClick={() => {
                this.props.history.push(`/${this.props.currentLang}/`);
              }}
            >
              {localization.home[currentLang]}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});
const PasswordThankWithRouter = withRouter(PasswordThank);
export default connect(mapStateToProps)(PasswordThankWithRouter);
