import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from 'react-router-dom';
import Routes from './Routes';
import { ConfigProvider } from 'antd';
import React, { Component } from 'react';
import './antd.css';
import './scss/pages/app.scss';
import { connect } from 'react-redux';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { setCurrentLang } from './redux/user/user.action';
import {Helmet} from "react-helmet";
import meta from './Localization/meta.json';
import * as Sentry from "@sentry/react";

const queryClient = new QueryClient();
class App extends Component {
  componentDidMount() {
    // this.getLanguage();
    // console.log('app.js', this.props);

    if (this.props.location.pathname.includes('/en')) {
      localStorage.setItem('lang', 'en');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
      this.props.dispatch(setCurrentLang('en'));
    } else { 
      localStorage.setItem('lang', 'ar');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      this.props.dispatch(setCurrentLang('ar'));
    }

    // if (localStorage.getItem('lang') === null) {
    //   localStorage.setItem('lang', 'en');
    //   document.getElementsByTagName('html')[0].setAttribute('lang', 'en');
    //   this.props.dispatch(setCurrentLang('en'));
    // } else {
    //   document
    //     .getElementsByTagName('html')[0]
    //     .setAttribute('lang', this.props.currentLang);
    // }

    var uniq = Date.now().toString(36) + Math.random().toString(36).substr(2);
    if (localStorage.getItem('deviceId') === null) {
      localStorage.setItem('deviceId', uniq);
    }
  }

  getLanguage = () => {
    if (localStorage.getItem('lang') === null) {
      localStorage.setItem('lang', 'ar');
      document.getElementsByTagName('html')[0].setAttribute('lang', 'ar');
      this.props.dispatch(setCurrentLang('ar')); 
    } else {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('lang', this.props.currentLang);
    }
  };

  render() {
    const { currentLang } = this.props;
    return (
      <QueryClientProvider client={queryClient}>
        <div className="app-wrapper">
          <ConfigProvider direction={currentLang === 'en' ? 'ltr' : 'rtl'}>
            <Helmet>
                <title>{meta.KhairZaman[currentLang]}</title>
                <meta name="description" content={meta.KhairZamandescription[currentLang]} />
                {/* <link rel="canonical" href="http://mysite.com/example" /> */}
            </Helmet>
            <Router>
              <Switch>
                <Routes />
              </Switch>
            </Router>
          </ConfigProvider>
        </div>
      </QueryClientProvider>
    );
  }
}

const SentryApp = Sentry.withProfiler(App);
const AppWithRouter = withRouter(SentryApp);

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(AppWithRouter);
