import { Button, message, Modal, Skeleton } from "antd";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { addProductToCart, clearCart } from "../../redux/cart/cart.action";
import ProductCard from "../ProductCard/ProductCard";
import { ExclamationCircleOutlined, DeleteTwoTone } from "@ant-design/icons";
import "./CartProducts.scss";
import { fetchCart } from "../../network/product.network";
import localization from "../../Localization/Localization.json";
import emptyCart from "../../assets/empty-shopping.gif";
import { Link } from "react-router-dom";
import { EmptyCart } from "../../network/cart.network";
import { Link as I18Link } from "react-router-i18n";

const { confirm } = Modal;
class CartProducts extends PureComponent {
  state = {
    isLoading: true,
    cartInfo: null,
    cartItems: [],
  };

  componentDidMount() {
    const { isLoading, cartItemsList } = this.props;
    // console.log(cartItemsList, "cartItemsList")
    this.setState({ isLoading, cartItems: cartItemsList });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cartItemsList !== this.state.cartItems) {
      // console.log(this.props.cartItemsList, "this.props.cartItemsList");
      this.setState({ cartItems: [] }, () => {
        this.setState({ cartItems: this.props.cartItemsList });
      });
    }
    if (this.props.isLoading !== this.state.isLoading) {
      this.setState({ isLoading: this.props.isLoading });
    }
  }

  removeItemFromList = (productDltId) => {
    // const { cartItems } = this.state;
    // const filterCartItems = cartItems.filter(item => item.id !== productDltId);
    // this.setState({cartItems : filterCartItems})
    this.props.removeCardInCard(productDltId);
    //   fetchCart((res) => {
    //     this.setState({isLoading : true})
    //     console.log(res.data.data.cart)
    //     this.setState({cartInfo : res.data.data.cart, cartItems : res.data.data.cart.items.map(item => (
    //       {...item,
    //         productId : +item.id,
    //         id : +item.productId,
    //         inCart: {
    //           itemId: +item.id,
    //           quantity: item.quantity,
    //         },
    //     })) , isLoading : false})
    // }, (error) => {
    //     this.setState({ isLoading : false})
    //     console.log(error)
    // })
  };

  render() {
    const { isLoading, cartInfo, cartItems } = this.state;
    const { currentLang } = this.props;
    return (
      <div className="cart-products-wrapper">
        <div className="cart-products-content">
          {cartItems.length === 0 && !isLoading && (
            <div className="empty-cart-wrapper">
              <img style={{ width: 240 }} src={emptyCart} alt="" />
              <h4>{localization.YourCartEmpty[currentLang]}</h4>
              <Button type="primary" size="large">
                <I18Link to={{ pathname: "/" }}>
                  {localization.BackToHome[currentLang]}
                </I18Link>
              </Button>
            </div>
          )}
          <div className="cart-products--list">
            {isLoading ? (
              <>
                <Skeleton avatar title={false} active></Skeleton>
                <Skeleton avatar title={false} active></Skeleton>
              </>
            ) : (
              cartItems?.map((product, idx) => (
                <ProductCard
                  removeItemFromList={this.removeItemFromList}
                  key={idx}
                  showCartCard
                  card={product}
                />
              ))
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(CartProducts);
