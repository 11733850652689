import { Button, Form, Input, InputNumber, message, Modal, Spin } from 'antd';
import React, { Component } from 'react'
import { phoneValidationEN, phoneValidationAR } from '../../constants/constants';
import { fetchProfile, updatePassword, updateProfile } from '../../network/profile.network';
import { MailOutlined, UserOutlined, PhoneOutlined, LockOutlined } from '@ant-design/icons';
import { updateCurrentUser } from '../../redux/user/user.action';
import { connect } from "react-redux";
class PersonalData extends Component { 
  formUserRef = React.createRef();
    state = {
        btnLoading : false,
        btnPassLoading : false, 
        userInfo : null,
        passwordModalVisible : false,
        isLoading : true
    }

    componentDidMount() {
      fetchProfile((res) => {
        const userInfo = res.data.data.user;
        // this.setState({userInfo})
        this.setState({isLoading : false})
        setTimeout(() => {
          this.formUserRef?.current?.setFieldsValue({
            Firstname : userInfo?.first_name || "",
            Lastname : userInfo?.last_name || "",
            Phonenumber : userInfo?.phone,
            Emailaddress : userInfo?.email,  
            // password : userInfo?.password, 
          });
        }, 600)
    }, (error) => {
        this.setState({isLoading : false})
        console.log(error);
    })
    }
    

    onFinish = (values) => {
        this.setState({btnLoading : true})
        let body = new FormData();
        body.append("first_name", values.Firstname);
        body.append("last_name", values.Lastname);
        body.append("phone", values.Phonenumber);
        body.append("email", values.Emailaddress);
        // body.append("password", values.password);
        updateProfile(
          body,
          (res) => {
            this.setState({btnLoading : false})
            if(res.data.code === 200){ 
              message.success(res.data.message); 
              this.props.dispatch(updateCurrentUser({...res.data.data.user})) 
            }
          },
          (error) => {
            console.log(error);  
            this.setState({btnLoading : false})
          }
        );
        
      };

      onFinishChangePass = (values) => {
        this.setState({btnPassLoading : true})
        let body = new FormData();
        body.append("oldPassword", values.currentpassword);
        body.append("newPassword", values.newpassword);
        body.append("newPassword_confirmation", values.confirm); 
        updatePassword(
          body,
          (res) => {
            this.setState({btnPassLoading : false})
            if(res.data.code === 200){ 
              message.success(res.data.message); 
              this.setState({passwordModalVisible : false}) 
            }
          },
          (error) => {
            console.log(error);  
            this.setState({btnPassLoading : false})
          }
        );
        
      };

    render() {
        const {btnLoading, passwordModalVisible, btnPassLoading, isLoading} = this.state;
        const {localization, currentLang} = this.props;
        return (
          <Spin spinning={isLoading} >
            <div className="personal-data-wrapper">
              <p className="form-edit-text">
                {localization.Edityourdata[currentLang]}
              </p>
          <div className="form-holder-2-col">
            <Form
              name="basic"
              onFinish={this.onFinish} 
              ref={this.formUserRef}
            >
              <Form.Item
                label={localization.Firstname[currentLang]}
                name="Firstname"
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.Firstname[currentLang]}` },
                ]}
                className="input-holder group-floating-label"
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} className="input-control" />
              </Form.Item>
              <Form.Item
                label={localization.Lastname[currentLang]}
                name="Lastname"
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.Lastname[currentLang]}` },
                ]}
                className="input-holder group-floating-label"
              >
                <Input prefix={<UserOutlined className="site-form-item-icon" />} className="input-control" />
              </Form.Item>
              <Form.Item
                label={localization.Phonenumber[currentLang]}
                name="Phonenumber"
                rules={currentLang === "en" ? phoneValidationEN : phoneValidationAR} 
                className="input-holder group-floating-label"
              >
                <Input prefix={<PhoneOutlined className="site-form-item-icon" />} className="input-control" />
              </Form.Item>

              <Form.Item
                label={localization.EmailAddress[currentLang]}
                name="Emailaddress"
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.EmailAddress[currentLang]}` },
                  {type: 'email',  message : localization.Emailvalid[currentLang]}
                ]} 
                className="input-holder group-floating-label"
              >
                <Input prefix={<MailOutlined className="site-form-item-icon" />} className="input-control" /> 
              </Form.Item>
                {!this.props?.currentUser?.isSocial &&
                  <div className="form-hinte-holder" style={{gridColumn: "span 2"}}>
                      <p>{localization.likechangeyourpassword[currentLang]}  <span onClick={() => {this.setState({passwordModalVisible : true})}}>{localization.changepassword[currentLang]}</span></p>
                  </div> 
                }
            <div className="btn-action-holder" style={{gridColumn: "span 2"}}>
                <Button size="large" style={{marginTop : 16}} type="primary" htmlType="submit" loading={btnLoading}>
                {localization.Save[currentLang]}  
                </Button>
            </div>
            </Form>
            </div> 
            <Modal destroyOnClose 
            closable title={localization.changepassword[currentLang]}
             onCancel={() => {this.setState({passwordModalVisible : false})}} footer={null} visible={passwordModalVisible}>
            <div className="form-holder-1-col">
            <Form
              name="basic"
              onFinish={this.onFinishChangePass} 
              // ref={this.formUserRef}
            >

              <Form.Item
                name="currentpassword" 
                label={localization.currentpassword[currentLang]}
                className="input-holder group-floating-label"
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.currentpassword[currentLang]}` },
                ]} 
                hasFeedback
              >
                <Input.Password  prefix={<LockOutlined className="site-form-item-icon" />} className="input-control"  />
              </Form.Item>

              <Form.Item
                name="newpassword" 
                label={localization.newpassword[currentLang]}
                className="input-holder group-floating-label"
                rules={[ 
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.newpassword[currentLang]}` },
                ]} 
                hasFeedback
              >
                <Input.Password  prefix={<LockOutlined className="site-form-item-icon" />} className="input-control"  />
              </Form.Item>

              <Form.Item
                name="confirm"
                label= {localization.confirmpassword[currentLang]}
                dependencies={['newpassword']}  
                className="input-holder group-floating-label"
                hasFeedback
                rules={[
                  { required: true, message: `${localization.Pleaseinput[currentLang]} ${localization.confirmpassword[currentLang]}` },
                  ({ getFieldValue }) => ({
                    validator(_, value) { 
                      if (!value || getFieldValue('newpassword') === value) { 
                        return Promise.resolve();
                      } 

                      return Promise.reject(new Error(localization.Thetwopasswords[currentLang]));
                    },
                  }),
                ]}
              >
                <Input.Password  prefix={<LockOutlined className="site-form-item-icon" />} className="input-control" />
              </Form.Item> 
            <div className="btn-action-holder" style={{gridColumn: "span 2"}}>
                <Button size="large" style={{marginTop : 16}} type="primary" htmlType="submit" loading={btnPassLoading}>
                {localization.Save[currentLang]} 
                </Button>
            </div>
            </Form>
            </div>
            </Modal>
            </div>
            </Spin>
        )
    }
}

const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(PersonalData);

