import React from "react";
import ReactPixel from "react-facebook-pixel";
function FaceBookPixelCheckout(props) {
  const { handelTotalPrice, cartItems } = props;
  React.useEffect(() => {
    console.log(props);
    ReactPixel.track("Purchase", {
      contents: [
        ...cartItems?.map(({ itemId, quantity }) => ({
          id: itemId,
          quantity,
        })),
      ],
      content_type: "product",
      value: handelTotalPrice(),
      currency: "EGP",
    });
  });
  return <></>;
}

export default FaceBookPixelCheckout;
