import { presistDataTypes } from './presistData.type';

const INITIAL_STATE = {
  presistHomeData: null,
  presistMyOrders: null,
  loading: true,
};

const presistReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case presistDataTypes.PRESIST_DATA:
      return {
        ...state,
        presistHomeData: payload,
        loading: false,
      };

    case presistDataTypes.FETCH_MY_ORDERS:
      return {
        ...state,
        presistMyOrders: payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default presistReducer;
