import React, { Component } from 'react'
import { connect } from 'react-redux';
import NoProduct from "../../assets/no-product.gif"
class NoProducts extends Component {
    render() {
        return (
            <div className="no-products-wrapper">
                <div className="img-holder">
                    <h4>{ this.props.currentLang === "en" ? "no products found" : "لا توجد منتجات"}</h4> 
                    <img src={NoProduct} alt="No Product" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentLang : state.user.currentLang,
}) 

export default connect(mapStateToProps)(NoProducts);
