import React, { Component } from 'react';
import {
  Breadcrumb,
  Button,
  Divider,
  Pagination,
  Radio,
  Skeleton,
  Slider,
  Tabs,
} from 'antd';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import { Collapse, Checkbox } from 'antd';
import './Category.scss';
import { connect } from 'react-redux';
import {
  FilterOutlined,
  PlusOutlined,
  MinusOutlined,
  CloseOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import ProductCard from '../../components/ProductCard/ProductCard';
import {
  fetchBrand,
  fetchFilterData,
  getLvl1Categories,
  getCategoryProducts,
  fetchSearch,
  searchFilterationData,
  fetchLvl1Products,
  dealFilterationData,
} from '../../network/product.network';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
import { fallbackImg } from '../../constants/constants';
import NoProducts from '../../components/NoProducts/NoProducts';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import { fetchOffers } from '../../network/home.network';
import { Helmet } from 'react-helmet';
import meta from '../../Localization/meta.json';

const { Panel } = Collapse;
const { TabPane } = Tabs;
class Category extends Component {
  state = {
    PriceRange: [0, 1000],
    // categoryProducts : [],
    categoryProducts: [],
    categoryLevels: [],
    loading: true,
    filterLoading: false,
    sortVal: false,
    withPrice: false,
    filterData: null,
    filterDataChecked: [],
    CatDataChecked: [],
    categoryShow: null,
    paginationList: [],
    activeLevel : 1,
  };

  onChangeSlider = (value) => {
    this.setState({ PriceRange: value });
    // this.handelFilterData(true);
  };

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidMount() {
    const { match, location } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);

    this.unlisten = this.props.history.listen((location, action) => {
      this.state.tabsLvlId && this.setState({tabsLvlId : false, pagination : false});
      setTimeout(() => {
      const body = document.querySelector('#root');
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
      if (location.pathname.includes('/search')) {
        setTimeout(() => {
          const { match } = this.props;
          this.setState({ loading: true, filterDataChecked: [],  CatDataChecked: [], paginationList : [], sectionTitle: match.params.name });
          let params = `${match.params.name}`;
          // console.log(params);
          fetchSearch(
            params,
            (res) => {
              this.setState({
                loading: false,
                categoryProducts: res.data.data.products,
                pagination: res.data.data.pagination,
                categoryShow: 'brand',
              });
            },
            (error) => {
              console.log(error);
              this.setState({ loading: false });
            }
          );
          searchFilterationData(
            match.params.name,
            (res) => {
              this.setState({ filterData: res.data.data });
            },
            (error) => {
              console.log(error);
            }
          );
        }, 400);
      } else if (location.pathname.includes('/deals')) { 
        this.setState({ loading: true, filterDataChecked : [], CatDataChecked : [], paginationList : [], sectionTitle : localization.Deals[this.props.currentLang] });
        let params = '';
        params += `deal=${1}`;
        fetchBrand(
          params,
          (res) => {
            this.setState({
              categoryProducts: res.data.data.products,
              pagination: res.data.data.pagination,
              loading: false,
              categoryShow: 'brand',
            });
          },
          (error) => {
            console.log(error);
            this.setState({ loading: false });
          }
        );
        dealFilterationData(
          (res) => {
            this.setState({ filterData: res.data.data });
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (location.pathname.includes('/brand')) {
        this.setState({ loading: true });
        setTimeout(() => {
          const { match } = this.props;
          let params = `${match.params.name}`;
          this.setState({ filterDataChecked: [`${match.params.id}`] });
          // console.log(params);
          fetchSearch(
            params,
            (res) => {
              // 
              this.setState({
                loading: false,
                categoryProducts: res.data.data.products,
                pagination: res.data.data.pagination,
                categoryShow: 'brand',
              });
            },
            (error) => {
              // console.log(error);
              this.setState({ loading: false });
            }
          );
        }, 400);
      }
      if (location.pathname.includes('/category')) {
        setTimeout(() => {
          this.handelFtechLvlProducts();
        }, 500);
      }
    });
  }, 500);
    this.handelSectionRouteData();
  }

  handelSectionRouteData = (page) => {
    const { match, location } = this.props;
    const { pagination, withPrice, PriceRange, tabsLvlId } = this.state;
    let currentpage = page ? page : pagination?.current || 0;
    let params = '';
    tabsLvlId && this.setState({tabsLvlId : false});
    if(withPrice){
      params += `priceFrom=${PriceRange[0]}&`;
      params += `priceTo=${PriceRange[1]}&`;
    }
    if (location.pathname.includes('/brand/')) {
      // in brand section
      this.setState({ categoryShow: 'brand' });
      params += `brandIds[0]=${match.params.id}`;
      // console.log(params);
      fetchBrand(
        params,
        (res) => {
          this.setState({
            categoryProducts: res.data.data.products,
            pagination: res.data.data.pagination,
            sectionTitle: match.params.name,
            loading: false,
          });
          setTimeout(() => {
            this.setState({ filterDataChecked: [`${match.params.id}`] });
          }, 1000);
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
    } else if (location.pathname.includes('/search')) {
      this.setState({ loading: true, paginationList : [], filterDataChecked : [], CatDataChecked : [], sectionTitle: match.params.name});
      params += `${match.params.name}&`;
      if (pagination?.totalPages > 1 && currentpage) {
        params += `page=${currentpage}&`;
      }
      fetchSearch(
        params,
        (res) => {
          
          this.setState({
            loading: false,
            pagination: res.data.data.pagination,
            categoryProducts: res.data.data.products,
            categoryShow: 'brand',
          });
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );

      searchFilterationData(
        match.params.name,
        (res) => {
          this.setState({ filterData: res.data.data });
        },
        (error) => {
          console.log(error);
        }
      );
    } else if (location.pathname.includes('/deals')) {
      const { sortVal, PriceRange } = this.state;
      this.setState({ loading: true, filterDataChecked : [], CatDataChecked : [], paginationList : [], sectionTitle : localization.Deals[this.props.currentLang] });
      let params = '';
      params += `deal=${1}&`;
      if (sortVal) {
        params += `sort=${sortVal}&`;
      }
      if(withPrice){
        params += `priceFrom=${PriceRange[0]}&`;
        params += `priceTo=${PriceRange[1]}&`; 
      }
      if (pagination?.totalPages > 1 && currentpage) {
        params += `page=${currentpage}&`;
      }
      // console.log(params);
      fetchBrand(
        params,
        (res) => {
          this.setState({
            categoryProducts: res.data.data.products,
            pagination: res.data.data.pagination,
            loading: false,
            categoryShow: 'brand',
          });
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
      dealFilterationData(
        (res) => {
          this.setState({ filterData: res.data.data });
        },
        (error) => {
          console.log(error);
        }
      );
    }
    if (location.pathname.includes('/category')) {
      setTimeout(() => {
        this.handelFtechLvlProducts(currentpage);
      }, 500);
    }
  };

  handelFtechLvlProducts = (page) => {
    const { match, location } = this.props;
    const { pagination, sortVal, withPrice, PriceRange } = this.state;
    let currentpage = page ? page : pagination?.current || 0;
    this.setState({ loading: true, filterDataChecked: [], CatDataChecked : []});
    let params = '';
    params += `categoryIds[0]=${+match.params.id}&`;
    params += `level=${+match.params.lvlId}&`;
    if (sortVal) {
      params += `sort=${sortVal}&`;
    }
    if(withPrice){
      params += `priceFrom=${PriceRange[0]}&`;
      params += `priceTo=${PriceRange[1]}&`; 
    }
    if (pagination?.totalPages > 1 && currentpage) {
      params += `page=${currentpage}&`;
    }
    fetchLvl1Products(
      params,
      (res) => {
        this.setState({
          categoryProducts: res.data.data.products,
          pagination: res.data.data.pagination,
          loading: false,
          sectionTitle: match.params.name,
        });

        getLvl1Categories(
          match.params.id,
          match.params.lvlId, 
          (res) => {
            if(res.data.code === 200){
          this.setState({ filterData: res.data.data, paginationList : [0], activeLevel : +match.params.lvlId });
            }else{
              this.setState({paginationList : []})
            }
          },
          (error) => {
            console.log(error);
          }
        );
      },
      (error) => {
        this.setState({ loading: false });
        console.log(error);
      }
    );
  };

  handelFtechLvl3Products = (id, page) => {
    const { match, location } = this.props;
    const { pagination, sortVal, withPrice, PriceRange,activeLevel } = this.state;
    let currentpage = page ? page : pagination?.current || 0;
    this.setState({ loading: true, filterDataChecked: [], CatDataChecked : [] });
    // console.log(match, 'matchmatchmatchmatch');
    // console.log(match.params.id, 'matchmatchmatchmatch');
    let params = '';
    params += `categoryIds[0]=${+id}&`;
    params += `level=${activeLevel + 1}&`;
    if (sortVal) {
      params += `sort=${sortVal}&`;
    }
    if (location.pathname.includes('/deals')) {
      params += `deal=${1}&`;
    }
    if(withPrice){
      params += `priceFrom=${PriceRange[0]}&`;
      params += `priceTo=${PriceRange[1]}&`; 
    }
    if (pagination?.totalPages > 1 && currentpage) {
      params += `page=${currentpage}&`;
    }
    fetchLvl1Products(
      params,
      (res) => {
        this.setState({
          categoryProducts: res.data.data.products,
          pagination: res.data.data.pagination,
          loading: false,
          // sectionTitle: match.params.name,
        });

        // getLvl1Categories(
        //   match.params.id,
        //   match.params.lvlId, 
        //   (res) => {
        //     if(res.data.code === 200){
        //       this.setState({ filterData: res.data.data, paginationList : [0] });
        //     }else{
        //       this.setState({paginationList : []})
        //     }
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );
      },
      (error) => {
        this.setState({ loading: false });
        console.log(error);
      }
    );
  };

  onChangeBrand = (checkedValues, page) => {
    this.setState(
      { filterDataChecked: checkedValues, categoryShow: 'brand' },
      () => {
        if (checkedValues.length > 0) {
          const { filterDataChecked, PriceRange, sortVal, pagination } =
            this.state;
          const { location } = this.props;
          let currentpage = page ? page : pagination?.current || 0;
          this.setState({ loading: true });
          let params = '';
          filterDataChecked.forEach((data, idx) => {
            params += `brandIds[${idx}]=${data}&`;
          });
          if (pagination?.totalPages > 1 && currentpage) {
            params += `page=${currentpage}&`;
          }
          if (this.props.location.pathname.includes('/search')) {
            const { match } = this.props;
            params += `keyword=${match.params.name}&`;
          }
          // if(withPrice){
          //   this.setState({filterLoading : true})
          //   params += `priceFrom=${PriceRange[0]}&`;
          //   params += `priceTo=${PriceRange[1]}&`;
          // }
          if (sortVal) {
            params += `sort=${sortVal}&`;
          }
          if (location.pathname.includes('/deals')) {
            params += `deal=${1}&`;
          }
          // if(location.pathname.includes('/brand/')){
          fetchBrand(
            params,
            (res) => {
              this.setState({
                categoryProducts: res.data.data.products,
                sectionTitle: this.props.match?.params?.name || '',
                pagination: res.data.data.pagination,
                loading: false,
                filterLoading: false,
              });
            },
            (error) => {
              console.log(error);
              this.setState({ loading: false, filterLoading: false });
            }
          );
          // }
        } else {
          this.handelSectionRouteData(1);
        }
      }
    );
  };

  onChangeCatCheckbox = (checkedValues, page) => {
    this.setState(
      { CatDataChecked: checkedValues, categoryShow: 'brand' },
      () => {
        if (checkedValues.length > 0) {
          const { CatDataChecked, PriceRange, sortVal, pagination } =
            this.state;
          const { location } = this.props;
          let currentpage = page ? page : pagination?.current || 0;
          this.setState({ loading: true });
          let params = '';
          params += "level=3&"
          CatDataChecked.forEach((data, idx) => {
            params += `categoryIds[${idx}]=${data}&`;
          });
          if (pagination?.totalPages > 1 && currentpage) {
            params += `page=${currentpage}&`;
          }
          if (this.props.location.pathname.includes('/search')) {
            const { match } = this.props;
            params += `keyword=${match.params.name}&`;
          }
          // if(withPrice){
          //   this.setState({filterLoading : true})
          //   params += `priceFrom=${PriceRange[0]}&`;
          //   params += `priceTo=${PriceRange[1]}&`;
          // }
          if (sortVal) {
            params += `sort=${sortVal}&`;
          }
          if (location.pathname.includes('/deals')) {
            params += `deal=${1}&`;
          }
          // if(location.pathname.includes('/brand/')){
          fetchBrand(
            params,
            (res) => {
              this.setState({
                categoryProducts: res.data.data.products,
                sectionTitle: this.props.match?.params?.name || '',
                pagination: res.data.data.pagination,
                loading: false,
                filterLoading: false,
              });
            },
            (error) => {
              // console.log(error);
              this.setState({ loading: false, filterLoading: false });
            }
          );
          // }
        } else {
          this.handelSectionRouteData(1);
        }
      }
    );
  };

  onChangePagination = (page) => {
    const { filterDataChecked, tabsLvlId, CatDataChecked } = this.state;
    const body = document.querySelector('.category-page-content');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
    if (filterDataChecked.length > 0) {
      this.onChangeBrand(filterDataChecked, page);
    }else if(CatDataChecked.length > 0){
      this.onChangeCatCheckbox(CatDataChecked, page);
    }
    else if(tabsLvlId){
      this.handelFtechLvl3Products(tabsLvlId, page);
    }
     else {
      this.handelSectionRouteData(page);
    }
  };

  handelOpenResFilter = (openMenu = true) => {
    if (openMenu) {
      document
        .getElementsByClassName('category-aside-holder')[0]
        .classList.add('active');
    } else {
      document
        .getElementsByClassName('category-aside-holder')[0]
        .classList.remove('active');
    }
  };

  onChangeSort = (checkedValues) => {
    this.setState({ sortVal: checkedValues.target.value }, () => {
      const { filterDataChecked, CatDataChecked } = this.state;
      if (filterDataChecked.length > 0) {
        this.onChangeBrand(filterDataChecked, 1);
      }else if(CatDataChecked.length > 0){
        this.onChangeCatCheckbox(CatDataChecked, 1);
      } else {
        this.handelSectionRouteData(1);
      }
    });
  };

  changeTabLvl = (tabId) => {
      // console.log(tabId, "tabId")
      this.setState({tabsLvlId : tabId})
      if(tabId === "-1"){
        this.handelSectionRouteData(1);
      }else{
        this.handelFtechLvl3Products(tabId, false);
      }
  }

  render() {
    const {
      paginationList,
      PriceRange,
      categoryProducts,
      categoryInfo,
      loading,
      filterData,
      sectionTitle,
      filterDataChecked,
      categoryShow,
      filterLoading,
      CatDataChecked,
      tabsLvlId,
      pagination,
      activeLevel
    } = this.state;
    const { currentLang, match, location } = this.props;
    const options = [
      { label: localization.Pricelowest[currentLang], value: 2 },
      { label: localization.Pricehighest[currentLang], value: 3 },
      { label: localization.Mostrelevant[currentLang], value: 6 },
      { label: localization.MostPopular[currentLang], value: 1 },
      { label: localization.AZ[currentLang], value: 4 },
      { label: localization.ZA[currentLang], value: 5 },
    ];

    return (
      <div className="category-page-wrapper">
        {location.pathname.includes("/deals") ? 
        <Helmet>
            <title>{meta.KhairZamanOffers[currentLang]}</title>
        </Helmet> : 
        <Helmet>
            <title>{meta.KhairZamanCategories[currentLang]}</title>
        </Helmet>
        }
        <Header />
        <div className="container">
          {/* <div className="category-hero">
            <h2>{sectionTitle || ''}</h2>
          </div> */}
          <div className="category-page-content">
            
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              {activeLevel === 1 && 
                  <Breadcrumb.Item>{filterData?.level1Category?.name}</Breadcrumb.Item>
              }
              {activeLevel === 2 && 
              <>
                  <Breadcrumb.Item>
                    <I18Link to={{ pathname: `/category/1/${filterData?.level1Category?.id}/${filterData?.level1Category?.name?.replace('/','').replace('%','')}` }}>
                      {filterData?.level1Category?.name}
                    </I18Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{filterData?.level2Category?.name}</Breadcrumb.Item>
              </>}
              {activeLevel === 3 && 
              <>
                  <Breadcrumb.Item>
                    <I18Link to={{ pathname: `/category/1/${filterData?.level1Category?.id}/${filterData?.level1Category?.name?.replace('/','').replace('%','')}` }}>
                      {filterData?.level1Category?.name}
                    </I18Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <I18Link to={{ pathname: `/category/2/${filterData?.level2Category?.id}/${filterData?.level2Category?.name?.replace('/','').replace('%','')}` }}>
                      {filterData?.level2Category?.name} 
                    </I18Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{filterData?.level3Categories?.name}</Breadcrumb.Item>
              </>}
            </Breadcrumb>
            <div
              className={`category-view-wrapper is-brand ${
                categoryShow === 'brand' && ' is-brand'
              } ${categoryShow === 'level3' && ' is-brand'}`}
            >
              <aside className="category-aside-holder">
                <div className="category-filter-holder">
                  <div className="filter-section">
                    <CloseOutlined
                      className="close-filter-slide"
                      onClick={() => this.handelOpenResFilter(false)}
                    />
                    <Collapse
                      defaultActiveKey={['1', '2', '3', '4']}
                      expandIconPosition="right"
                      ghost
                      expandIcon={({ isActive }) =>
                        isActive ? <MinusOutlined /> : <PlusOutlined />
                      }
                    >
                      <Panel className="sort-by-title" header={localization.Sortby[currentLang]} key="1">
                        <Radio.Group
                          size="large"
                          options={options}
                          onChange={this.onChangeSort}
                        />
                      </Panel>
                      <h4 className="filter-title">
                        {localization.Filterby[currentLang]}
                      </h4>
                      {activeLevel === 1 &&
                      <>
                      <Panel header={localization.Categories[currentLang]} key="2">
                        <Checkbox.Group
                          options={filterData?.level3Categories?.map(lvl => ({label : ""+lvl.name, value : ""+lvl.id}) )} 
                          onChange={(checkedValues) =>
                            this.onChangeCatCheckbox(checkedValues, 1)
                          }
                          value={CatDataChecked}
                          />
                      </Panel>
                      <Divider />
                      </>
                      }
                      <Panel header={localization.Brands[currentLang]} key="4">
                        <Checkbox.Group
                          options={filterData?.brands?.map((brand) => ({
                            label: '' + brand.name,
                            value: '' + brand.id,
                          }))}
                          onChange={(checkedValues) =>
                            this.onChangeBrand(checkedValues, 1)
                          }
                          value={filterDataChecked}
                        />
                      </Panel>
                      <Divider />
                      <Panel header={localization.Price[currentLang]} key="3">
                        <Slider
                          min={0}
                          max={1000}
                          range={{ draggableTrack: true }}
                          defaultValue={[0, 1000]}
                          onChange={this.onChangeSlider}
                        />
                        <div className="slider-price-rang">
                          <p className="price-start">
                            {localization.AED[currentLang]} {PriceRange[0]}
                          </p>
                          <p className="price-end">
                            {localization.AED[currentLang]} {PriceRange[1]}
                          </p>
                        </div>
                        <div className="text-right">
                        <Button
                          loading={filterLoading}
                          type="primary"
                          size="large"
                          style={{ marginTop: 20 }}
                          className="primary-light"
                          onClick={ () => {
                            this.setState({withPrice : true}, () => {
                              this.handelSectionRouteData();
                            })
                          }}
                        >
                          {localization.ApplyPrice[currentLang]}
                        </Button>
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              </aside>
              <div className="category-list-holder">
                {/* {categoryShow !== "brand" &&
                <div className="category-sections-holder">
                  {categoryLevels.map((section) => ( 
                      <div className="section-holder" key={section.id} onClick={() => this.handelSelectLvl(section)} > 
                        <img src={section.image || fallbackImg} alt={section.name} />
                        <p className="section-title">{section.name}</p>
                      </div>
                  ))}
                </div> 
                } */}
                <div className={"cat-header-title-filter"}>
                  {/* <h4 className="section-title">{sectionTitle && sectionTitle}</h4> */}
                  {activeLevel === 1 && filterData?.level2Categories && <div className="category-levels-wrapper">
                    <Tabs activeKey={tabsLvlId ? tabsLvlId : "-1"} onChange={this.changeTabLvl}>
                    <TabPane tab={localization.All[currentLang]} key={"-1"}></TabPane>
                      {filterData?.level2Categories?.map(lvl => 
                        <TabPane tab={lvl.name} key={lvl.id}></TabPane>
                      )}
                    </Tabs>
                  </div>}
                  {activeLevel === 2 && filterData?.level3Categories && <div className="category-levels-wrapper">
                    <Tabs activeKey={tabsLvlId ? tabsLvlId : "-1"} onChange={this.changeTabLvl}>
                    <TabPane tab={localization.All[currentLang]} key={"-1"}></TabPane>
                      {filterData?.level3Categories?.map(lvl => 
                        <TabPane tab={lvl.name} key={lvl.id}></TabPane>
                      )}
                    </Tabs>
                  </div>}
                  <FilterOutlined onClick={this.handelOpenResFilter} />
                </div>
                <div className="category-product-wrapper">
                  {!loading ? (
                    categoryProducts?.length > 0 ? (
                      categoryProducts?.map((card) => (
                        <ProductCard discount card={card} />
                      ))
                    ) : (
                      <NoProducts />
                    )
                  ) : (
                    [0, 0, 0, 0, 0, 0, 0, 0, 0].map((skl) => (
                      <Skeleton
                        block
                        avatar
                        size="large"
                        avatarShape="square"
                        active
                        paragraph={{ rows: 4 }}
                      />
                    ))
                  )}
                </div>
                {pagination && pagination?.totalPages > 1 && (
                  <div className="pagination-wrapper">
                    <Pagination
                      responsive
                      onChange={this.onChangePagination}
                      current={pagination?.current}
                      pageSize={pagination?.size}
                      total={pagination?.total}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const categoryWithRouter = withRouter(Category);
const mapStateToProps = (state) => ({
  productsList: state.products.productsList,
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(categoryWithRouter);
