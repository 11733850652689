import { Breadcrumb, Pagination, Skeleton, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link as I18Link } from 'react-router-i18n';
import { Link, withRouter } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ProductCard from '../../components/ProductCard/ProductCard';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import {
  fetchOffers,
  fetchOffersType,
  fetchSingleRecipe,
} from '../../network/home.network';
import localization from '../../Localization/Localization.json';
import '../Recipes/Recipes.scss';
import axios from 'axios';

import './SingleRecipes.scss';
import { Helmet } from 'react-helmet';
import meta from '../../Localization/meta.json';

class SingleRecipes extends Component {
  state = {
    recipe: null,
    recipesList: [],
    loading: true,
  };

  componentDidMount() {
    const { match, location } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'smooth'
    }, 500);
    this.unlisten = this.props.history.listen((location, action) => {
      const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
      this.setState({
        loading: true,
      });
      setTimeout(() => {
        if (match?.params?.id) {
          this.fetchSingleRecipeHandler();
        }
      }, 500);
    });

    if (match?.params?.id) {
      this.fetchSingleRecipeHandler();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentLang, match } = this.props;
    if (currentLang !== prevProps.currentLang) {
      this.setState({ loading: true });
      this.fetchSingleRecipeHandler();
    }
  }

  componentWillUnmount() {
    this.unlisten();
  }

  fetchSingleRecipeHandler = () => {
    const { match } = this.props;
    fetchSingleRecipe(
      match.params.id,
      (res) => {
        this.setState({
          recipe: res.data.data.recipe,
          recipesList: res.data.data.relatedRecipes,
          loading: false,
        });
      },
      (err) => {
        this.setState({
          loading: false,
        });
      }
    );
  };

  // componentWillMount() {
  //   this.unlisten = this.props.history.listen((location, action) => {
  //     this.setState({ isLoading: true });
  //     setTimeout(() => {
  //       const { match } = this.props;
  //       console.log(match);
  //       if (match?.params?.id) {
  //         axios
  //           .get(
  //             `https://www.themealdb.com/api/json/v1/1/lookup.php?i=${match?.params?.id}`
  //           )
  //           .then((res) => {
  //             const list = res.data.meals[0];
  //             this.setState({ recipe: res.data.meals[0] });
  //           });
  //       }
  //     }, 500);
  //   });
  // }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  render() {
    const { currentLang, match } = this.props;
    const { offerList, loading, pagination, recipe, recipesList } = this.state;
    return (
      <div className="recipes-page-wrapper">
         <Helmet>
            <title>{recipe?.name && (recipe?.name +" | "+ meta.KhairZaman[currentLang])}</title>
        </Helmet> 
        <Header />
        <div className="container">
          {/* <div className="recipes-hero"></div> */}
          <div className="recipes-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/recipes' }}>
                  {localization.Recipes[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{recipe?.name}</Breadcrumb.Item>
            </Breadcrumb>

            <Spin spinning={loading}>
              <div className="recipe-wrapper">
                <div className="recipe-content-holder">
                  {recipe?.image && (
                    <div className="recipe--img">
                      <img src={recipe?.image} alt="" />
                    </div>
                  )}

                  <section className="recipe-section">
                    <h4 className="recipe-section--title">{recipe?.name}</h4>
                    <p className="recipe-section--text">
                      {recipe?.description.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, ' ')}
                    </p>
                  </section>

                  <section className="recipe-section">
                    <h4 className="recipe-section--title">
                      {localization.howToMakeIt[currentLang]}
                    </h4>

                    {this.state.recipe?.steps?.map((step, index) => (
                      <div className="recipe-step-holder" key={step.id}>
                        <h5 className="recipe-step--title">
                          {localization.step[currentLang]} {index + 1}
                        </h5>
                        <p className="recipe-step--text">{step.description}</p>
                      </div>
                    ))}

                    {/* <div className="recipe-step-holder">
                    <h5 className="recipe-step--title">Step 1</h5>
                    <p className="recipe-step--text">Preheat broiler.</p>
                  </div>
                  <div className="recipe-step-holder">
                    <h5 className="recipe-step--title">Step 2</h5>
                    <p className="recipe-step--text">
                      Combine rind, 1 tablespoon juice, garlic, oil, salt, and
                      pepper. Toss with bell pepper, tomatoes, chicken, and
                      onion. Thread vegetables and chicken onto 4 (12-inch)
                      skewers. Place skewers on a broiler pan coated with
                      cooking spray. Broil 12 minutes or until chicken is done,
                      turning occasionally.
                    </p>
                  </div>
                  <div className="recipe-step-holder">
                    <h5 className="recipe-step--title">Step 3</h5>
                    <p className="recipe-step--text">
                      Combine 1 teaspoon juice, yogurt, sour cream, and pesto.
                      Serve sauce with kebabs.
                    </p>
                  </div> */}
                  </section>

                  <section className="recipe-section">
                    <h4 className="recipe-section--title">
                      {localization.Ingredients[currentLang]}
                    </h4>
                    {this.state.recipe?.ingredients?.map((item) => (
                      <p key={item.id} className="recipe-section--text">
                        {item.product.description}
                      </p>
                    ))}
                    {/*                   
                  <p className="recipe-section--text">
                    2 teaspoons grated lemon rind
                  </p>
                  <p className="recipe-section--text">
                    4 teaspoons fresh lemon juice, divided
                  </p>
                  <p className="recipe-section--text">
                    2 teaspoons bottled minced garlic
                  </p>
                  <p className="recipe-section--text">2 teaspoons olive oil</p>
                  <p className="recipe-section--text">1/2 teaspoon salt</p>
                  <p className="recipe-section--text">
                    1/4 teaspoon black pepper
                  </p> */}
                  </section>

                  <section className="single-recipe-style">
                    {this.state.recipe?.ingredients?.map((item) => (
                      <ProductCard card={item.product} />
                    ))}
                  </section>
                </div>
                <div className="recipe-aside-holder">
                  {recipesList.map((card) => (
                    <div className="recipes-card-holder">
                      <I18Link
                        to={{
                          pathname: `/recipes/${card.id}`,
                        }}
                      >
                        <div className="recipes-card-img">
                          <img src={card.image} alt="" />
                        </div>
                        <div className="recipes-card-content">
                          <h4 className="recipes-card--title">{card.name}</h4>
                          {/* <p className="recipes-card--text">
                            {' '}
                            {card?.description.replace(/<\/?[^>]+(>|$)/g, '')}
                          </p> */}
                        </div>
                      </I18Link>
                    </div>
                  ))}
                </div>
              </div>
            </Spin>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const RecipesSingleWithRouter = withRouter(SingleRecipes);
const mapStateToProps = (state) => ({
  //   productsList: state.products.productsList.slice(200, 230),
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(RecipesSingleWithRouter);
