import { Breadcrumb, Button, Image, message, Select, Spin } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import localization from '../../Localization/Localization.json';
import { fallbackImg } from '../../constants/constants';
import share from '../../assets/share.svg';
import del_icon from '../../assets/img/del.svg';
import './SingleProduct.scss';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteFilled,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  HeartTwoTone,
  HeartFilled,
} from '@ant-design/icons';
import {
  addProductUserCart,
  favProductUserCart,
  fetchProductInfo,
  removeProductUserCart,
  updateProductUserCart,
} from '../../network/product.network';
import {
  addProductToCart,
  removeProductToCart,
} from '../../redux/cart/cart.action';
import DiscountSection from '../../components/DiscountSection/DiscountSection';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import BranchModal from '../../components/BranchModal/BranchModal';
import { Helmet } from 'react-helmet';
import meta from '../../Localization/meta.json';

const { Option } = Select;
class SingleProduct extends Component {
  state = {
    isCardAdded: false,
    card_amount: null,
    btnLoading: false,
    updateLoading: false,
    branchModalVisible: false, 
    card: null,
    relatedProducts: [],
    isLoading: true,
  };

  componentDidMount() {
    this.handelFetchProductInfo();
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
  }

  handelFetchProductInfo = () => {
    const { match, cartItems } = this.props;
    this.setState({ isLoading: true });
    // window.scrollTo({top: 0, behavior: 'smooth'});
    fetchProductInfo( 
      match?.params?.id,
      (res) => {
        // 
        // console.log(res.data.data);
        const card = res.data.data.product;
        const relatedProducts = res.data.data.relatedProducts;
        this.setState({ isLoading: false });
        if (res.data.code === 200) {
          const quantityIdxActive =
            card?.inCart && card?.quantities.indexOf(card?.inCart?.quantity);
          if (card.inCart) {
            this.setState({ isCardAdded: true });
            const isProductExistInStorage = cartItems.some(
              (product) => product.id === card.id
            );
            if (!isProductExistInStorage) {
              this.props.dispatch(addProductToCart({ ...card }));
            }
          } else {
            this.setState({ isCardAdded: false });
          }
          this.setState({
            card,
            activeCutting:
              card.cuttingTypes.length !== 0 ? card.cuttingTypes[0] : null,
            card_amount: {
              quantities: card.quantities,
              inCart: card.inCart,
              quantitiesIndex: quantityIdxActive || 0,
            },
            relatedProducts,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };

  handelDltProduct = () => {
    const { card } = this.state;
    this.setState({ updateLoading: true });
    let body = new FormData();
    body.append('itemId', card?.inCart?.itemId);
    removeProductUserCart(
      body,
      (res) => {
        this.setState({ updateLoading: false });
        if (res.data.code === 200) {
          this.setState({
            isCardAdded: false,
            card: { ...this.state.card, inCart: null },
          });
          message.success(res.data.message);
          this.props.dispatch(removeProductToCart(card.id));
        }
      },
      (error) => {
        this.setState({ updateLoading: false });
      }
    );
  };

  handelFavProduct = () => {
    const { card } = this.state;
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('product', +card?.id);
    favProductUserCart(
      body,
      (res) => {
        this.setState({ btnLoading: false });
        if (res.data.code === 200) {
          message.success(res.data.message);
          this.setState({ card: { ...card, isFavorite: res.data.item } });
        }
      },
      (error) => {
        this.setState({ btnLoading: false });
      }
    );
  };

  handelUpdateProduct = (controlType) => {
    const { card_amount, card, activeCutting } = this.state;
    if (card?.inCart) {
      this.setState({ updateLoading: true });
      let body = new FormData();
      body.append('itemId', card?.inCart?.itemId);
      body.append(
        'quantity',
        card?.quantities[
          controlType === 'inc'
            ? card_amount?.quantitiesIndex + 1
            : card_amount?.quantitiesIndex - 1
        ]
      );
      if (activeCutting?.id) {
        body.append('cuttingTypeId', activeCutting?.id);
      }
      updateProductUserCart(
        body,
        (res) => {
          this.setState({ updateLoading: false });
          if (res.data.code === 200) {
            message.success(
              localization.productupdatedsuccessfully[this.props.currentLang]
            );
            controlType === 'inc'
              ? this.setState({
                  card_amount: {
                    ...card_amount,
                    quantitiesIndex: +card_amount.quantitiesIndex + 1,
                  },
                })
              : this.setState({
                  card_amount: {
                    ...card_amount,
                    quantitiesIndex: +card_amount.quantitiesIndex - 1,
                  },
                });
          }
        },
        (error) => {
          this.setState({ updateLoading: false });
          console.log(error);
        }
      );
    } else {
      controlType === 'inc'
        ? this.setState({
            card_amount: {
              ...card_amount,
              quantitiesIndex: +card_amount.quantitiesIndex + 1,
            },
          })
        : this.setState({
            card_amount: {
              ...card_amount,
              quantitiesIndex: +card_amount.quantitiesIndex - 1,
            },
          });
    }
  };

  UNSAFE_componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({ isLoading: true });
      setTimeout(() => {
        this.handelFetchProductInfo();
        const body = document.querySelector('#root');
        body.scrollIntoView({
          behavior: 'smooth'
        }, 500);
      }, 500);
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }

  handelAddProductToCart = (product) => {
    const { card_amount, card, activeCutting } = this.state;
    const { currentBranch, currentLang } = this.props;
    if (!this.props.currentBranch) {
      this.setState({ branchModalVisible: true });
      return false;
    }
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('productId', product.id);
    body.append('quantity', product.quantities[card_amount?.quantitiesIndex]);
    if (activeCutting?.id) {
      body.append('cuttingTypeId', activeCutting?.id);
    }
    addProductUserCart(
      body,
      (res) => {
        if(res.data.code === 101){
          message.warning(`${localization.productnotexistinbranch[currentLang]} ${currentBranch.name}`);
        }
        if (res.data.code === 200) {
          message.success(res.data.message);
          let newCard = { ...card };
          newCard.inCart = {
            itemId: res.data.data.item.itemId,
            quantity: res.data.data.item.quantity,
          };
          this.setState({
            isCardAdded: true,
            btnLoading: false,
            card: newCard,
          });
          this.props.dispatch(
            addProductToCart({
              ...product,
              inCart: {
                itemId: res.data.data.item.itemId,
                quantity: res.data.data.item.quantity,
              },
              product_cart_count: card_amount?.quantitiesIndex,
            })
          );
        } else {
          this.setState({ btnLoading: false });
        }
      },
      (error) => {
        this.setState({ btnLoading: false });
        console.log(error);
      }
    );
  };

  handleChangeProductAmount = (value) => {
    const { card, card_amount, activeCutting, isCardAdded } = this.state;
    const quantityIdxActive = card?.quantities.indexOf(value);
    // console.log(quantityIdxActive)
    if (isCardAdded) {
      this.setState({ updateLoading: true });
      let body = new FormData();
      body.append('itemId', card?.inCart?.itemId);
      body.append('quantity', card?.quantities[quantityIdxActive]);
      if (activeCutting?.id) {
        body.append('cuttingTypeId', activeCutting?.id);
      }
      updateProductUserCart(
        body,
        (res) => {
          this.setState({ updateLoading: false });
          if (res.data.code === 200) {
            message.success(
              localization.productupdatedsuccessfully[this.props.currentLang]
            );
            this.setState({
              card_amount: {
                ...card_amount,
                quantitiesIndex: +quantityIdxActive,
              },
            });
          }
        },
        (error) => {
          this.setState({ updateLoading: false });
          console.log(error);
        }
      );
    } else {
      this.setState({
        card_amount: {
          ...card_amount,
          quantitiesIndex: +quantityIdxActive,
        },
      });
    }
  };

  handleChangeProductType = (value) => {
    this.setState({
      activeCutting: { ...this.state.activeCutting, id: value },
    });
  };
  render() {
    const { currentLang } = this.props;
    const {
      branchModalVisible,
      card_amount,
      updateLoading,
      btnLoading,
      card,
      relatedProducts,
      isLoading,
      activeCutting,
    } = this.state;
    return (
      <div className="product-page-wrapper">
        <Helmet>
            <title>{card?.name &&  card?.name +" | "+ meta.KhairZaman[currentLang]}</title>
        </Helmet>
        <Header />
        <BranchModal
          closeModal={() => this.setState({ branchModalVisible: false })}
          branchModalVisible={branchModalVisible}
        />
        <div className="container">
          <div className="product-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              {/* <Breadcrumb.Item>
                <Link to="/category/2">women</Link> 
              </Breadcrumb.Item> */}
              <Breadcrumb.Item>{card?.name}</Breadcrumb.Item>
            </Breadcrumb>
            <Spin spinning={isLoading}>
              <div className="product-info-wrapper">
                <div className="product-info-content">
                  <div className="product-img-holder">
                    {card?.discount !== "0" && (
                      <p className="product-discount">{card?.discount}%</p>
                    )}
                    <Image
                      src={card?.image || fallbackImg}
                      fallback={fallbackImg}
                      preview={false}
                    />
                  </div>
                  <div className="product-details-holder">
                    <div className="product-name-holder">
                      <h4>{card?.name}</h4>

                      <div className="price-holder">
                        <p className="price-after">
                          {card?.oldPrice !== "0" && (
                            <p className="price-before">
                              {card?.oldPrice} {localization.AED[currentLang]}
                            </p>
                          )}
                          {card?.finalPrice}
                          <span>{localization.AED[currentLang]}</span>
                        </p>
                        <div className="product-type">
                          <Select
                            value={
                              card?.quantities[card_amount.quantitiesIndex]
                            }
                            style={{ width: 80 }}
                            onChange={this.handleChangeProductAmount}
                            className="select-product-amount select-product"
                          >
                            {card?.quantities.map((quantity) => (
                              <Option value={quantity}>{quantity}</Option>
                            ))}
                          </Select>
                          {card?.cuttingTypes.length > 0 && (
                            <Select
                              value={activeCutting?.id}
                              style={{ width: 100 }}
                              onChange={this.handleChangeProductType}
                              className="select-product-type select-product"
                            >
                              {card?.cuttingTypes.map((cutting) => (
                                <Option value={cutting.id}>
                                  {cutting.name}
                                </Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="product-desc">
                      {/* <h5>{localization.Description[currentLang]}</h5> */}
                      <p>{card?.description}</p>
                    </div>
                    <div className="product-cart-wrapper">
                      {card?.inCart ? (
                        <div className="card-count-btns">
                          <img
                            src={del_icon}
                            alt="delete"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handelDltProduct(card)}
                          />
                          <div className="card-control">
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 24 }}
                                  spin
                                />
                              }
                              spinning={updateLoading}
                            >
                              <div className="card-count-holder btns-wrapper">
                                <Button
                                  onClick={() =>
                                    this.handelUpdateProduct('dec')
                                  }
                                  disabled={card_amount?.quantitiesIndex === 0}
                                >
                                  <MinusOutlined />
                                </Button>
                                <p className="product-count">
                                  {
                                    card_amount?.quantities[
                                      card_amount?.quantitiesIndex
                                    ]
                                  }
                                </p>
                                <Button
                                  onClick={() =>
                                    this.handelUpdateProduct('inc')
                                  }
                                  disabled={
                                    card_amount?.quantitiesIndex ===
                                    card?.quantities?.length - 1
                                  }
                                >
                                  <PlusOutlined />
                                </Button>
                              </div>
                            </Spin>
                          </div>
                        </div>
                      ) : (
                        <Button
                          loading={btnLoading}
                          type="primary"
                          size="large"
                          // block
                          style={{ width: 222 }}
                          className="add-btn"
                          onClick={() => this.handelAddProductToCart(card)}
                        >
                          {localization.Addtocart[currentLang]}
                        </Button>
                      )}

                      {/* <div className="add-fav-dlt-holder">
                      {card?.inCart ? (
                        <div className="product-fav-dlt">
                          <DeleteFilled
                            className="dlt-btn"
                            onClick={() => this.handelDltProduct(card)}
                          />
                          {card?.isFavorite ? (
                            <HeartFilled
                              style={{ color: "#ff4d4f" }}
                              onClick={() => this.handelFavProduct(card)}
                            />
                          ) : (
                            <HeartTwoTone
                              onClick={() => this.handelFavProduct(card)}
                              twoToneColor={"#00c2ff"}
                            />
                          )}
                        </div>
                      ) : (
                        <Button
                          loading={btnLoading}
                          type="primary"
                          size="large"
                          block
                          className="add-btn"
                          onClick={() => this.handelAddProductToCart(card)}
                        >
                          {localization.Addtocart[currentLang]}
                        </Button>
                      )}
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="product-section-wrapper" style={{ minHeight: 200 }}>
              {/* <Spin spinning={isLoading}>    */}
              {relatedProducts.length > 0 && (
                <DiscountSection
                  title={localization.RelatedProducts[currentLang]}
                  isSingle
                  discoundList={relatedProducts}
                />
              )}
              {/* </Spin> */}
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}
const SingleProductWithRouter = withRouter(SingleProduct);

const mapStateToProps = (state) => ({
  cartItems: state.cart.cartItems,
  currentLang: state.user.currentLang,
  currentBranch: state.user.currentBranch,
});

export default connect(mapStateToProps)(SingleProductWithRouter);
