export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_LANG = 'SET_LANG';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_BRANCH = 'SET_BRANCH';
export const SET_PAYMENT = 'SET_PAYMENT';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const SHOW_ORDER = 'SHOW_ORDER';
export const SHOW_BRANDS = 'SHOW_BRANDS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_AREAS = 'SET_AREAS';
export const SET_USER_ADDRESS = 'SET_USER_ADDRESS';
