import React, { Component } from "react";
import { Button, Modal, Result } from "antd";
import SliderSection from "../SliderSection/SliderSection";
import { Link as I18Link } from 'react-router-i18n';
import localization from '../../Localization/Localization.json'
import { connect } from "react-redux";
class AlternativeModal extends Component {
  state = {
    showModal: false,
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidUpdate(prevProps) {
    if (this.props.altModalVisible !== this.state.showModal) {
      this.setState({ showModal: this.props.altModalVisible }, () => {
      });
    }
  }

  render() {
    const { showModal } = this.state;
    const { alternatives, currentLang } = this.props;
    return (
      <div className="branch-modal-wrapper">
        <Modal
          wrapClassName="branch-modal alt-modal" 
          onCancel={this.props.closeModal}
          title={localization.Selectalternativeproduct[currentLang]}
          // Selectalternativeproduct
          footer={null}
          visible={showModal}
        >
          <div className="alt-slider-wrapper">
            {alternatives?.length > 0 ?
              <SliderSection sliderList={alternatives || []} /> :
              <Result
                  status="404"
                  title=""
                  subTitle={localization.noalternatives[currentLang]}
                  extra={
                    <Button type="primary" size="large">
                      <I18Link to={{ pathname: '/' }} style={{ color: '#FFF' }}>
                        {localization.BackToHome[currentLang]}
                      </I18Link>
                    </Button>
                    
                  }
                />
            }
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang : state.user.currentLang,
}) 

export default connect(mapStateToProps)(AlternativeModal);