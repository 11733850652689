import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';

import lines from '../../assets/img/lines.svg';
import vegt from '../../assets/img/vegt.png';
import fruits from '../../assets/img/fruits.jpg';
import localization from '../../Localization/Localization.json';

import './TrendingBrand.scss';
import { fallbackImg } from '../../constants/constants';

const TrendingBrand = ({ data, currentLang }) => {
  return (
    <div className="trending-brand-wrapper">
      <div className="trending-bg-left">
        <div className="trending-lines">
          <img src={lines} alt="" />
        </div>
        <div className="trending-hero"> 
          <I18Link to={{ pathname: `/category/1/${data[0]?.id}/${data[0]?.name?.replace('/','').replace('%','')}` }}>
            <img src={data[0]?.image || fallbackImg} alt="" />
          </I18Link>
        </div>
        <div className="content-title">
          <h2>{data[0]?.name}</h2>
          <I18Link to={{ pathname: `/category/1/${data[0]?.id}/${data[0]?.name?.replace('/','').replace('%','')}` }}>
            {localization.showproducts[currentLang]}
          </I18Link>
        </div>
      </div>
      <div className="trending-brand-content">
        {data.slice(1, 9)?.map((brand) => (
          <I18Link
            to={{ pathname: `/category/1/${brand.id}/${brand.name?.replace('/','').replace('%','')}` }}
            key={brand.id}
            className="brand-holder"
          >
            <img src={brand.image || fallbackImg} alt={brand.name} />
            <p className="brand-title">{brand.name}</p>
          </I18Link>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(TrendingBrand);
