import {
  SET_CATEGORIES,
  SET_AREAS,
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  SET_LANG,
  SET_ADDRESS,
  SET_BRANCH,
  SET_PAYMENT,
  SHOW_ORDER,
  SHOW_BRANDS,
  SET_USER_ADDRESS,
} from './user.type';

export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user,
});

export const setCategories = (data) => ({
  type: SET_CATEGORIES,
  payload: data,
});

export const setListAreas = (data) => ({
  type: SET_AREAS,
  payload: data,
});

export const showOrderList = () => ({
  type: SHOW_ORDER,
});

export const setBrandsList = (list) => ({
  type: SHOW_BRANDS,
  payload: list,
});

export const setAddressUser = (address) => ({
  type: SET_ADDRESS,
  payload: address,
});

export const setBranchUser = (branch) => ({
  type: SET_BRANCH,
  payload: branch,
});

export const setPaymentUser = (payment) => ({
  type: SET_PAYMENT,
  payload: payment,
});

export const updateCurrentUser = (user) => ({
  type: UPDATE_CURRENT_USER,
  payload: user,
});

export const setCurrentLang = (user) => ({
  type: SET_LANG,
  payload: user,
});

export const setAddress = (userAdress) => ({
  type: SET_USER_ADDRESS,
  payload: userAdress,
});
