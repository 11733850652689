import ReactPixel from "react-facebook-pixel";
export const addToCartPixel = ({
  finalPrice,
  inCart: { itemId, quantity },
}) => {
  ReactPixel.track("AddToCart", {
    contents: [
      {
        id: itemId,
        quantity,
      },
    ],
    content_type: "product",
    value: finalPrice,
    currency: "EGP",
  });
};
