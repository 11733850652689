import {
  Breadcrumb,
  Button,
  Image,
  Skeleton,
  Modal,
  message,
  Spin,
} from 'antd';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import { Form, Input } from 'antd';
import truckLine from '../../assets/img/truck-line.png';
import { connect } from 'react-redux';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import { fallbackImg } from '../../constants/constants';
import localization from '../../Localization/Localization.json';
import { fetchSingleOrder } from '../../network/home.network';
import './SingleOrder.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CancellationReasons from '../../components/CancellationReasons/CancellationReasons';
import { Reorder } from '../../network/profile.network';
const { confirm } = Modal;

class SingleOrder extends Component {
  formUserRef = React.createRef();
  state = {
    orders: [],
    showReasonsModal: false,
    isLoading: false,
  };

  componentDidMount() {
    const { match } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
    this.setState({ isLoading: true });
    fetchSingleOrder(
      match.params.id,
      (res) => {
        this.setState(
          {
            isLoading: false,
            orders: res.data.data.order,
          },
          () => {
            res.data.data.order.comment &&
              this.formUserRef?.current?.setFieldsValue({
                Comment: res.data.data.order.comment,
              });
          }
        );
      },
      (err) => {
        this.setState({ isLoading: false });
        console.log(err);
      }
    );
  }

  handelReorder = (orderId) => {
    confirm({
      title: localization.reorderheseorder[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        let body = new FormData();
        body.append('orderId', orderId);
        Reorder(
          orderId,
          body,
          (res) => {
            
            if (res.data.code === 200) {
              message.success(res.data.message);
              this.props.history.push(`/${this.props.currentLang}/cart`);
            }
          },
          (error) => {
            console.log(error);
            // this.setState({btnLoading : false})
          }
        );
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  render() {
    const { currentLang, match } = this.props;
    const { showReasonsModal, isLoading, orders } = this.state;
    return (
      <div className="single-order-page">
        <Header />
        <CancellationReasons
          closeModal={() => this.setState({ showReasonsModal: false })}
          showModalVisible={showReasonsModal}
          orderId={match.params.id}
          currentLang={currentLang}
          localization={localization}
        />
        <Spin spinning={isLoading}>
          <div className="container single-order-page-bg">
            <div className="single-order-breadcrumb">
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <I18Link to={{ pathname: '/' }}>
                    {localization.home[currentLang]}
                  </I18Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <I18Link
                    to={{
                      pathname: `/profile/3/ordrers`,
                    }}
                  >
                    {localization.Myordrers[currentLang]}
                  </I18Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>#{orders?.id}</Breadcrumb.Item>
              </Breadcrumb>
              {orders?.status?.id === 5 && (
                <Button
                  size="middle"
                  style={{ width: 120 }}
                  type="danger"
                  ghost
                  className="cancel-order-btn order-cancel-btn reorder-btn"
                  onClick={() => this.handelReorder(this.state.orders.id)}
                >
                  {localization.reorderBtn[currentLang]}
                </Button>
              )}
              {orders?.status?.id !== 3 &&
                orders?.status?.id !== 5 &&
                orders?.status?.id !== 4 &&
                !orders?.cancelled &&
                !isLoading && (
                  <Button
                    size="middle"
                    style={{ width: 120 }}
                    type="danger"
                    ghost
                    className="cancel-order-btn order-cancel-btn"
                    onClick={() => this.setState({ showReasonsModal: true })}
                  >
                    {localization.Cancelorder[currentLang]}
                  </Button>
                )}
            </div>
            <div className="single-order-content">
              <div className="single-order-content-items">
                <div className="checkout-items-list order-list-items">
                  {isLoading ? (
                    <>
                      <Skeleton avatar title={false} active></Skeleton>
                      <Skeleton avatar title={false} active></Skeleton>
                    </>
                  ) : (
                    this.state.orders?.items?.map((item) => (
                      <div className="item-holder orders-holder" key={item.id}>
                        <div className="product-img">
                          <Image
                            src={item?.image_link || item?.image || fallbackImg}
                            fallback={fallbackImg}
                            preview={false}
                          />
                        </div>
                        <div className="product-details-wrapper orders-details-wrapper">
                          <div className="product-info">
                            <h6 className="product-name">
                              {item?.productName || item?.name}
                            </h6>
                            <span className="product-count">
                              x {item?.quantity}
                              {/* x {Math.trunc(item?.quantity)} */}
                            </span>
                          </div>

                          <div className="price-holder">
                            <p className="price-after">
                              <span>{item?.price}</span>{' '}
                              <span>{localization.AED[currentLang]}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                  <div className="order-comment">
                    <Form ref={this.formUserRef}>
                      <Form.Item
                        label={localization.comment[currentLang]}
                        name="Comment"
                        className="input-holder group-floating-label no-icon order-input-label"
                      >
                        <Input
                          className="input-control input-order-comment"
                          disabled
                        />
                      </Form.Item>
                    </Form>
                    {!orders?.cancelled && (
                      <div className="order-comment-delivered">
                        <img src={truckLine} alt="truck-icon" />
                        <p>{localization.orderTime[currentLang]}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="single-order-price-info">
                <div className="single-order-price-info-top">
                  <p className="single-order-info-container">
                    <span className="single-order-info-item">
                      {localization.Subtotal[currentLang]}
                    </span>
                    <span className="single-order-info-item__green">
                      {parseInt(this.state.orders?.subtotal || 0).toFixed(2)}
                    </span>
                  </p>

                  <p className="single-order-info-container">
                    <span className="single-order-info-item">
                      {localization.Discount[currentLang]}
                    </span>
                    <span className="single-order-info-item__green">
                      {parseInt(
                        this.state.orders?.discountPercentage || 0
                      ).toFixed(2)}{' '}
                      %
                    </span>
                  </p>

                  <p className="single-order-info-container">
                    <span className="single-order-info-item single-order-info-item__bold">
                      {localization.TOTAL[currentLang]}{' '}
                      <span className="single-order-vat">
                        ({localization.PricesincludeVAT[currentLang]})
                      </span>
                    </span>
                    <span className="single-order-info-item__green">
                      {parseInt(this.state.orders?.total || 0).toFixed(2)}
                    </span>
                  </p>
                </div>
                <div className="single-order-info-footer">
                  <p className="single-order-info-footer-container">
                    <span className="single-order-info-item-title">
                      {localization.branch[currentLang]}
                    </span>
                    <span className="single-order-info-item-subtitle">
                      {this.state.orders?.branch}
                    </span>
                  </p>
                  <p className="single-order-info-footer-container">
                    <span className="single-order-info-item-title">
                      {localization.Address[currentLang]}
                    </span>
                    <span className="single-order-info-item-subtitle">
                      {this.state.orders?.address}
                    </span>
                  </p>
                  <p className="single-order-info-footer-container">
                    <span className="single-order-info-item-title">
                      {localization.Payment[currentLang]}
                    </span>
                    <span className="single-order-info-item-subtitle">
                      {this.state.orders?.paymentType}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Spin>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const SingleOrderWithRouter = withRouter(SingleOrder);

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

export default connect(mapStateToProps)(SingleOrderWithRouter);
