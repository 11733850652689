import { createI18n } from 'react-router-i18n';

const locales = ['en', 'ar'];

const translations = {
  en: {
    home: 'Home',
    about: 'About',
    topics: 'Topics',
    helloWelcome: 'Welcome Back',
    homePage: {
      title: 'Home',
      text: 'This is the homepage',
    },
    aboutPage: {
      title: 'About',
      text: 'About the project',
    },
    topicPage: {
      select: 'Please select a topic',
      components: 'Components',
    },
    rendering: 'rendering',
    components: 'components',
    props_v_state: 'props-v-state',
  },

  ar: {
    home: 'الرئيسية',
    about: 'عنا',
    topics: 'توبيكس',
    helloWelcome: 'مرحبا بعودتك',
    homePage: {
      title: 'الرئيسية',
      text: 'الصفحة الرئيسية',
    },
    aboutPage: {
      title: 'عنا',
      text: 'البروجيكتس',
    },
    topicPage: {
      select: 'صفحة التوبيكس',
      components: 'Components',
    },
    rendering: 'rendering',
    components: 'components',
    props_v_state: 'props-v-state',
  },
};

const I18n = createI18n(locales, translations);

export default I18n;
