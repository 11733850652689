import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';

export default class NoMatch extends Component {
  render() {
    return (
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button type="primary">
              {' '}
              <I18Link to={{ pathname: '/' }} style={{ color: '#FFF' }}>
                Back Home
              </I18Link>
            </Button>
          }
        />
      </div>
    );
  }
}
