import React, { Component } from 'react';
import { Link as I18Link } from 'react-router-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  Menu,
  Dropdown,
  Button,
  AutoComplete,
  Input,
  Badge,
  Spin,
  Tooltip,
  Drawer,
} from 'antd';
import {
  AlignLeftOutlined,
  CloseOutlined,
  UserOutlined,
  ShoppingCartOutlined,
  LoadingOutlined,
  ImportOutlined,
  CaretDownOutlined,
  SearchOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import './Header.scss';
import { connect } from 'react-redux';
import {
  setCurrentUser,
  setCurrentLang,
  setAddressUser,
  setPaymentUser,
  setBrandsList,
  setCategories,
  setBranchUser,
  setListAreas,
} from '../../redux/user/user.action';
import AddressModal from '../../components/AddressModal/AddressModal';
import { clearCart } from '../../redux/cart/cart.action';
import localization from '../../Localization/Localization.json';
import logo from '../../assets/img/logo-home.png';
import logoS from '../../assets/img/Logo.png';
// import location_icon from "../../assets/img/loc.svg"
import location_icon from '../../assets/img/loc-white.svg';
import black_user from '../../assets/img/black_user.svg';
import black_cart from '../../assets/img/black_cart.svg';
import search_icon from '../../assets/search.svg';
import { cart_icon } from '../../icons/icons';
import { fetchHome } from '../../network/home.network';
import { fetchSearch } from '../../network/product.network';
import BranchModal from '../BranchModal/BranchModal';
import { getApi } from '../../network/API';
import { LevelsTreeEP, ListAreasEP } from '../../network/EndPoints';
import I18n from '../../I18n/I18n';
const { Search } = Input;
const { SubMenu } = Menu;
const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;
const stripLocale = (pathname, locale) => {
  if (!locale) {
    return pathname;
  }
  return pathname.replace(`/${locale}`, '');
};

class Header extends Component {
  state = {
    showSearch: false,
    showModalVisible: false,
    branchModalVisible: false,
    categoryVisible: false,
    drawerVisible: false,
    categoryLoading: false,
    categoriesList: null,
    MoreIpad: window.matchMedia("(min-width: 992px)").matches,
  };
  

  componentDidMount() {
    // if(this.props.location.pathname.includes('/profile')){
    //     this.setState({})
    // }
    // if (this.props.brandList?.length === 0) {
    //   fetchHome(
    //     (res) => {
    //       this.props.dispatch(setBrandsList(res.data.data.brands));
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }
    const handler = (e) => this.setState({ MoreIpad: e.matches }, () => {});
    window.matchMedia("(min-width: 992px)").addListener(handler);

  }

  handelMenuSelect = (val) => {
    if (val.key === '3') {
      localStorage.removeItem('token');
      this.props.dispatch(setCurrentUser(null));
      this.props.dispatch(setAddressUser(null));
      this.props.dispatch(setPaymentUser(null));
      this.props.dispatch(setBranchUser(null));
      this.props.dispatch(clearCart());
      if (
        this.props.location.pathname.includes('/profile') ||
        this.props.location.pathname.includes('/checkout')
      ) {
        this.props.history.push(`/${this.props.currentLang}/`);
      }
      if (this.props.location.pathname.includes('/cart')) {
        setTimeout(() => {
          window.location.reload();
        }, 400);
      }
      if(this.props.location.pathname === "/" || this.props.location.pathname === "/en/" || this.props.location.pathname === "/ar/" ){
        setTimeout(() => {
          try {
            this.props?.fetchHomeDetails();
          } catch (error) {
            window.location.reload();
          }
        }, 200);
      }
    }
  };

  handelLogOut = () => {
    localStorage.removeItem('token');
    this.props.dispatch(setCurrentUser(null));
    this.props.dispatch(setAddressUser(null));
    this.props.dispatch(setPaymentUser(null));
    this.props.dispatch(setBranchUser(null));
    this.props.dispatch(clearCart());
    if (
      this.props.location.pathname.includes('/profile') ||
      this.props.location.pathname.includes('/checkout')
    ) {
      this.props.history.push(`/${this.props.currentLang}/`);
    }
    if (this.props.location.pathname.includes('/cart')) {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    }
    if(this.props.location.pathname === "/" || this.props.location.pathname === "/en/" || this.props.location.pathname === "/ar/" ){
      setTimeout(() => {
        try {
          this.props?.fetchHomeDetails();
        } catch (error) {
          window.location.reload();
        }
      }, 200);
    }
    document.getElementsByClassName('main-header-bottom')[0].classList.remove('active');
  }

  changeLang = (lang) => {
    this.setState({categoryVisible: false})
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
    localStorage.setItem('lang', lang);
    this.props.dispatch(setCurrentLang(lang));
    this.props.dispatch(setBrandsList([]));
    this.props.dispatch(setBranchUser(null));
    this.props.dispatch(setCategories(null));
    document.getElementsByClassName('category-menu-dropdown')[0]?.classList.remove('active');
    // setTimeout(() => {
    //   getApi(ListAreasEP, (res) => {
    //     if(res.data.code === 200){
    //         this.setState({ListAreas : res.data.data.areas, areaLoading : false, })
    //         this.props.dispatch(setListAreas(res.data.data.areas)); 
    //     }
    // }, (err) => {
    //     console.log(err) 
    //     this.setState({areaLoading : false})
    // })
    // }, 1500);
};

  handelOpenMenu = (openMenu = true) => {
    if (openMenu) {
      document
        .getElementsByClassName('main-header-bottom')[0]
        .classList.add('active');
      // if (!this.state.MoreIpad) {
        if (!this.props.categories) {
          this.fetchCategoryList();
        } else {
          // console.log(this.props.categories);
          this.setState({ categoriesList: this.props.categories });
        }
      // }
    } else {
      document
        .getElementsByClassName('main-header-bottom')[0]
        .classList.remove('active');
    }
  };
  onSearchInput = (value) => {
    this.setState({ searchVal: value });
  };

  onSearch = (e, val = false) => {
    const value = val ? val : e.target.value;
    if (!!value) {
      this.props.history.replace(`/${this.props.currentLang}/search/${value}`);
      this.props.history.push(`/${this.props.currentLang}/search/${value}`);
    }
  };

  handelCategoryList = async () => {
      if (!this.props.categories) {
        this.setState({categoryLoading : true});
        await this.fetchCategoryList();
        setTimeout(() => {
          this.toggleCategoriesWithClass();
        }, 2000);

      } else {
        this.setState({ categoriesList: this.props.categories });
        this.toggleCategoriesWithClass();
      }
  };

  toggleCategoriesWithClass = () => {
    document.getElementsByClassName('category-menu-dropdown')[0].classList.toggle('active');
    this.setState({categoryVisible : !this.state.categoryVisible})
  }

  fetchCategoryList = () => {
    // this.setState({categoryLoading : true})
    getApi(
      LevelsTreeEP,
      (res) => {
        if (res.data.code === 200) {
          this.setState({ categoriesList: res.data.data.categories, categoryLoading : false });
          this.props.dispatch(setCategories(res.data.data.categories));
        }
      },
      (err) => {
        // this.setState({categoryLoading : false})
      }
    );
  };

  

  render() {
    const {
      MoreIpad,
      showModalVisible,
      branchModalVisible,
      categoriesList,
      categoryVisible,
      drawerVisible,
      categoryLoading,
    } = this.state;
    const { currentUser, currentLang, currentBranch } = this.props;
    const {
      location: { pathname },
      match: {
        params: { locale },
      },
      history,
    } = this.props;
    // console.log(MoreIpad);
    return (
      <div className="main-header">
        {/* <AddressModal noSetAddressLocal closeModal={() => this.setState({showModalVisible : false}) } showModalVisible={showModalVisible} /> */}
        <BranchModal
          fetchHomeDetails={this.props.fetchHomeDetails}
          closeModal={() => this.setState({ branchModalVisible: false })}
          branchModalVisible={branchModalVisible}
        />
        {MoreIpad && 
        <div className="category-menu-dropdown">
              <Menu
                          triggerSubMenuAction="click"
                          // forceSubMenuRender={true}
                          className="cat-menu-overlay-holder"
                          mode="vertical"
                        >
                          {/* <Spin spinning={categoryLoading}></Spin> */}
                          {categoriesList?.map((item) => (
                            <SubMenu
                              key={`L1v${item.id}`}
                              title={
                                <span
                                  onClick={() => {
                                    this.props.history.push(
                                      `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                                    );
                                    this.props.history.replace(
                                      `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                                    );
                                    this.toggleCategoriesWithClass();
                                    this.setState({ categoryVisible: false });
                                  }}
                                >
                                  {item.name}
                                </span>
                              }
                              popupClassName={`category-menu-lvl-1 ${
                                !categoryVisible && 'cat-menu-hidden'
                              }`}
                            >
                              {item.children.map((lvl2) => (
                                <SubMenu
                                  key={`L2v${lvl2.id}`}
                                  title={
                                    <span
                                      onClick={() => {
                                        this.props.history.push(
                                          `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                        );
                                        this.props.history.replace(
                                          `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                                        );
                                        this.toggleCategoriesWithClass();
                                        this.setState({
                                          categoryVisible: false,
                                        });
                                      }}
                                    >
                                      {lvl2.name}
                                    </span>
                                  }
                                  className={`${
                                    lvl2.children.length === 0 && 'menu-no-icon'
                                  }`}
                                  popupClassName={`category-menu-lvl-2 ${
                                    !categoryVisible && 'cat-menu-hidden'
                                  }`}
                                >
                                  {lvl2.children.map((lvl3) => (
                                    <Menu.Item
                                      key={`L3v${lvl3.id}`}
                                      onClick={() => {
                                        this.props.history.push(
                                          `/${this.props.currentLang}/category/3/${lvl3.id}/${lvl3.name?.replace('/','').replace('%','')}` 
                                        );
                                        this.props.history.replace(
                                          `/${this.props.currentLang}/category/3/${lvl3.id}/${lvl3.name?.replace('/','').replace('%','')}`
                                        );
                                        this.toggleCategoriesWithClass();
                                        this.setState({
                                          categoryVisible: false,
                                        });
                                      }}
                                    >
                                      {lvl3.name}
                                    </Menu.Item>
                                  ))}
                                </SubMenu>
                              ))}
                            </SubMenu>
                          ))}
                        </Menu>
                    </div>}
        <div className="main-header-content">
          <div className="main-header-top">
            <I18Link to={{ pathname: '/' }} className="khier-logo">
              {MoreIpad ? (
                <img src={logo} alt="logo" />
              ) : (
                <img src={logoS} alt="logo" />
              )}
            </I18Link>
            <AlignLeftOutlined
              onClick={this.handelOpenMenu}
              className="bars-menu-icon"
            />
            <div className="lang-search">
              <div className="menu-list-wrapper">
                <ul history={history}>
                  <li className="category-li">
                    <Spin spinning={categoryLoading} indicator={antIcon}></Spin>
                      <p onClick={this.handelCategoryList}>{localization.Categories[currentLang]}</p>
                  </li>
                  <li>
                    <I18Link to={{ pathname: '/deals' }}>
                      {localization.Deals[currentLang]}
                    </I18Link>
                  </li>
                  {currentLang === 'ar' &&
                  <li>
                    <I18Link to={{ pathname: '/recipes' }}>
                      {localization.Recipes[currentLang]}
                    </I18Link>
                  </li>
                  }
                  <li>
                    <I18Link to={{ pathname: '/about' }}>
                      {localization.About[currentLang]}
                    </I18Link>
                  </li>
                </ul>
              </div>

              <div className="search-input-holder">
                <AutoComplete
                  // options={["sdf", "wwwww", "waasw"]}
                  // onSelect={onSelect}
                  onSearch={this.onSearchInput}
                  // placeholder="input here"
                >
                  <Input
                    size="large"
                    placeholder={localization.Search[currentLang]}
                    onPressEnter={this.onSearch}
                    suffix={
                      <SearchOutlined
                        onClick={(e) => this.onSearch(e, this.state.searchVal)}
                      />
                    }
                  />
                </AutoComplete>
              </div>
            </div>
            <div className="cart-login">
              {currentBranch ? (
                <div
                  className="user-adderss-holder"
                  onClick={() => this.setState({ branchModalVisible: true })}
                >
                  <img src={location_icon} alt="" />
                  <Tooltip title={currentBranch.address}>
                    <p>{currentBranch.name}</p>
                  </Tooltip>
                </div>
              ) : (
                <div
                  className="user-adderss-holder"
                  onClick={() => this.setState({ branchModalVisible: true })}
                >
                  <img src={location_icon} alt="" />
                  <p>{localization.Choosebranch[currentLang]}</p>
                </div>
              )}
              <div className="cart-holder">
                <I18Link to={{ pathname: '/cart' }}>
                  <Badge count={this.props.cartItems.length} size="small">
                    {/* <ShoppingCartOutlined /> */}
                    <img src={black_cart} alt="" /> 
                  </Badge>
                </I18Link>
              </div>
              {currentUser ? (
                <>
                <img src={black_user} className="user-icon-holder" alt="" />
                <div className="user-drop-points">
                <Dropdown
                  overlayClassName="user-dropdown-wrapper" 
                  trigger={['click']}
                  overlay={
                    <Menu onClick={this.handelMenuSelect}>
                      <Menu.Item key="1" icon={<UserOutlined />}>
                        <I18Link to={{ pathname: '/profile' }}>
                          {localization.Profile[currentLang]}
                          </I18Link>
                      </Menu.Item>
                      <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                        <I18Link to={{ pathname: `/profile/3/orders` }}
                        >
                          {localization.Myordrers[currentLang]} 
                        </I18Link>
                      </Menu.Item>
                      <Menu.Item
                        key="3"
                        icon={<ImportOutlined />}
                        style={{ color: '#fe5252' }}
                      >
                        {localization.Logout[currentLang]}
                      </Menu.Item>
                    </Menu>
                  }
                  className="login-dropdown"
                >
                  <Button type="text">
                    {this.props.currentUser?.first_name || 'user'}
                    <CaretDownOutlined />
                  </Button>
                </Dropdown>
                <div className="points-tag">
                    <p>{localization.Points[currentLang]} : </p>
                    <span>560</span>
                </div>
                </div>
                </>
              ) : (
                <I18Link to={{ pathname: '/login' }}>
                <img className="user-icon-holder" src={black_user} alt="" />
                <span className="login-link">
                  {localization.Login[currentLang]}
                </span>
              </I18Link> 
              )}

              

              {/* {currentLang === 'en' ? (
                <p
                  className="lang-holder"
                  onClick={() => this.changeLang('ar')}
                >
                  AR
                </p>
              ) : (
                <p
                  className="lang-holder"
                  onClick={() => this.changeLang('en')}
                >
                  EN
                </p>
              )} */}

              <ul history={history}>
                {!this.props.location.pathname.includes('/en') ? (
                  <li onClick={() => this.changeLang('en')}>
                  <I18Link
                    className="lang-holder"
                    ignoreLocale
                    to={`/en${stripLocale(pathname, locale)}`}
                  >
                    <span>EN</span>
                  </I18Link>
                </li>
                ) : (
                  <li onClick={() => this.changeLang('ar')}>
                  <I18Link
                    className="lang-holder"
                    ignoreLocale
                    to={`/ar${stripLocale(pathname, locale)}`}
                  >
                    <span>AR</span>
                  </I18Link>
                </li>
                )}
              </ul>
            </div>
          </div>
          <div className="main-header-bottom">
            <CloseOutlined
              className="close-icon-menu"
              onClick={() => this.handelOpenMenu(false)}
            />

            <Drawer
              title={localization.Category[currentLang]}
              placement={currentLang === 'en' ? 'left' : 'right'}
              closable={false}
              onClose={() => this.setState({ drawerVisible: false })}
              visible={drawerVisible}
              className="drawer-main-header-bottom"
            >
              <Menu
                className="cat-res-menu-holder"
                triggerSubMenuAction="click"
                mode="inline"
              >
                {categoriesList?.map((item) => (
                  <SubMenu
                    key={`L1v${item.id}`}
                    title={
                      <span
                        onClick={() => {
                          this.props.history.push(
                            `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                          );
                          this.props.history.replace(
                            `/${this.props.currentLang}/category/1/${item.id}/${item.name}`
                          );
                          this.setState({ categoryVisible: false });
                        }}
                      >
                        {item.name}
                      </span>
                    }
                    popupClassName={`category-menu-lvl-1 ${
                      !categoryVisible && 'cat-menu-hidden'
                    }`}
                  >
                    {item.children.map((lvl2) => (
                      <SubMenu
                        key={`L2v${lvl2.id}`}
                        title={
                          <span
                            onClick={() => {
                              this.props.history.push(
                                `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                              );
                              this.props.history.replace(
                                `/${this.props.currentLang}/category/2/${lvl2.id}/${lvl2.name}`
                              );
                              this.setState({ categoryVisible: false });
                            }}
                          >
                            {lvl2.name}
                          </span>
                        }
                        className={`${
                          lvl2.children.length === 0 && 'menu-no-icon'
                        }`}
                        popupClassName={`category-menu-lvl-2 ${
                          !categoryVisible && 'cat-menu-hidden'
                        }`}
                      >
                        {lvl2.children.map((lvl3) => ( 
                          <Menu.Item
                            key={`L3v${lvl3.id}`}
                            onClick={() => {
                              this.props.history.push(
                                `/${this.props.currentLang}/category/3/${lvl3.id}/${lvl3.name?.replace('/','').replace('%','')}` 
                              );
                              this.props.history.replace(
                                `/${this.props.currentLang}/category/3/${lvl3.id}/${lvl3.name?.replace('/','').replace('%','')}`
                              );
                              this.setState({ categoryVisible: false });
                            }}
                          >
                            {lvl3.name}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ))}
                  </SubMenu>
                ))}
              </Menu>
            </Drawer>

            <ul>
              <li>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </li>
              <li onClick={() => this.setState({ drawerVisible: true })}>
                <a>{localization.Categories[currentLang]}</a>
              </li>
              <li>
                <I18Link to={{ pathname: '/deals' }}>
                  {localization.Deals[currentLang]}
                </I18Link>
              </li>
              {currentLang === 'ar' &&
              <li>
                <I18Link to={{ pathname: '/recipes' }}>
                      {localization.Recipes[currentLang]}
                    </I18Link>
              </li>
              }
              <li>
              <I18Link to={{ pathname: '/about' }}>
                {localization.About[currentLang]}
              </I18Link>
              </li>
              {currentUser ? 
              <li onClick={this.handelLogOut} className="log-out-holder">
              <a>{localization.Logout[currentLang]}</a>
              <LogoutOutlined />
            </li> :
              <li>
              <I18Link to={{ pathname: '/login' }}>
                {localization.Login[currentLang]}
              </I18Link>
              </li> 
            }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const HeaderWithRouter = withRouter(Header);
const mapStateToProps = (state) => ({
  currentUser: state.user.currentUser,
  currentLang: state.user.currentLang,
  // currentAddress : state.user.currentAddress,
  currentBranch: state.user.currentBranch,
  cartItems: state.cart.cartItems,
  categories: state.user.categories,
  // setCategories
});
export default connect(mapStateToProps)(HeaderWithRouter);
