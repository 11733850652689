import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  message,
  Spin,
  Row,
  Col,
  Divider,
} from 'antd';
import { Radio } from 'antd';
import { DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';

import no_addresses from '../../assets/img/no_addresses.svg';
import edit from '../../assets/img/edit.svg';

import './MyAddresses.scss';

import {
  addAddress,
  deleteAddress,
  fetchAddresses,
  updateAddress,
  selectAddress,
} from '../../network/profile.network';

import { setAddress, setAddressUser } from '../../redux/user/user.action';
import localization from '../../Localization/Localization.json';
import { getApi } from '../../network/API';

const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

class MyAddresses extends Component {
  formAddressRef = React.createRef();
  state = {
    selectAddress: null,
    btnLoading: false,
    addressList: [],
    showAddAdress: false,
    statesList: [],
    isEditId: false,
    isLoading: false,
    showAddBtn: true,
  };

  handelSetSelectAddress = (forceAddAdressLocal = false) => {
    const { addressList } = this.state;
    const selectedAddress = addressList.find(
      (address) => address.selected === 1
    );
    // this.setState({selectAddress : selectedAddress?.id || null})
    // forceAddAdressLocal && this.props.dispatch(setAddressUser(selectedAddress));
    // !this.props.noSetAddressLocal && this.props.dispatch(setAddressUser(selectedAddress));
  };

  onChangeAddress = (e) => {
    confirm({
      title: localization.Addressbemain[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: localization.Yes[this.props.currentLang],
      cancelText: localization.Cancel[this.props.currentLang],
      onOk: () => {
        let body = new FormData();
        body.append('addressId', e.target.value);
        selectAddress(
          body,
          (res) => {
            if (res.data.code === 200) {
              message.success(res.data.message);
              const selectedAddress = this.state.addressList.find(
                (ele) => +ele.id === +e.target.value
              );
              this.props.dispatch(
                setAddressUser({
                  ...selectedAddress,
                  fullAddress: selectedAddress.address,
                })
              );
              fetchAddresses(
                (res) => {
                  this.setState({ addressList: res.data.data.addresses });
                  this.props.closeModal();
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
          (error) => {
            console.log(error);
            // this.setState({btnLoading : false})
          }
        );
      },
      onCancel() {
      },
    });
  };

  componentDidMount() {
    const { userAddress } = this.props;

    if (!userAddress) {
      this.setState({ isLoading: true });
      this.presistUserAddress();
    } else {
      this.setState({
        isLoading: false,
        addressList: userAddress,
        // showAddAdress: true,
      });

      this.presistUserAddress();
    }
  }

  presistUserAddress = () => {
    fetchAddresses(
      (res) => {
        this.setState({ isLoading: false });
        if (res.data.data.addresses.length > 0) {
          this.setState({ showAddAdress: true });
        }

        this.props.dispatch(setAddress(res.data.data.addresses));
        this.setState({ addressList: res.data.data.addresses });
      },
      (error) => {
        console.log(error);
        this.setState({ isLoading: false });
      }
    );
  };

  handelDltAddress = (e, address) => {
    e.stopPropagation();
    e.preventDefault();
    // const addressStr = address.city_name + ", Street No. " + address.street_number + ", floor " + address.floor + "," + address.floor;
    confirm({
      title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: localization.Yes[this.props.currentLang],
      cancelText: localization.Cancel[this.props.currentLang],
      content: address.address,
      onOk: () => {
        let body = new FormData();
        body.append('address_id', address.id);
        deleteAddress(
          body,
          (res) => {
            // this.setState({btnLoading : false})
            
            if (res.data.code === 200) {
              message.success(res.data.message);
              fetchAddresses(
                (res) => {
                  if (res.data.data.addresses.length > 0) {
                    this.setState({ showAddAdress: true });
                  }
                  this.setState({ addressList: res.data.data.addresses }, () =>
                    this.handelSetSelectAddress()
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
          (error) => {
            console.log(error);
            // this.setState({btnLoading : false})
          }
        );
      },
      onCancel() {
      },
    });
  };

  handelEditAddress = (e, address) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isEditId: address.id, showAddBtn : false });
    getApi(
      `addresses/${address.id}`,
      (res) => {
        if (res.data.code === 200) {
          const addressRes = res.data.data.address;
          this.formAddressRef.current.setFieldsValue({
            streetName: addressRes?.streetName,
            buildingNumber: addressRes?.building,
            floor: addressRes?.floor,
            area: addressRes?.area,
            landmark: addressRes?.landmark || '',
            apartment: addressRes?.apartment,
            City: addressRes?.city,
          });
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  onFinish = (values) => {
    this.setState({ btnLoading: true });
    let body = new FormData();
    body.append('streetName', values.streetName);
    body.append('buildingNumber', values.buildingNumber);
    body.append('floor', +values.floor);
    body.append('area', values.area);
    body.append('landmark', values.landmark || '');
    body.append('city', values.City);
    body.append('apartment', values.apartment);
    if (this.state.isEditId) {
      body.append('address_id', +this.state.isEditId);
      updateAddress(
        body,
        (res) => {
          this.setState({ btnLoading: false, isEditId: false });
          if (res.data.code === 200) {
            message.success(res.data.message);
          }
          this.formAddressRef.current.resetFields();
          fetchAddresses(
            (res) => {
              this.setState(
                { addressList: res.data.data.addresses }
                // () => this.handelSetSelectAddress(true)
              );
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
          this.setState({ btnLoading: false });
        }
      );
    } else {
      addAddress(
        body,
        (res) => {
          this.setState({ btnLoading: false });
          if (res.data.code === 200) {
            message.success(res.data.message);
          }
          this.formAddressRef.current.resetFields();
          fetchAddresses(
            (res) => {
              if (res.data.data.addresses.length > 0) {
                this.setState({ showAddAdress: true });
              }
              this.setState({ addressList: res.data.data.addresses }, () =>
                this.handelSetSelectAddress()
              );
            },
            (error) => {
              console.log(error);
            }
          );
        },
        (error) => {
          console.log(error);
          this.setState({ btnLoading: false });
        }
      );
    }
  };

  render() {
    const {
      selectAddress,
      btnLoading,
      addressList,
      showAddAdress,
      statesList,
      isEditId,
      isLoading,
      showAddBtn,
    } = this.state;
    const { currentLang } = this.props;
    return (
      <Spin spinning={isLoading}>
        <div className="addresses-tab-wrapper">
          {addressList.length < 1 && !showAddAdress && (
            <div className="no-address-holder">
              <img src={no_addresses} alt="no addresses" />
              <p>{localization.Youanyaddressyet[currentLang]}</p>
              <Button
                size="large"
                style={{ width: 200 }}
                type="primary"
                className="primary-light"
                // ghost
                onClick={() => this.setState({ showAddAdress: true, showAddBtn : false })}
              >
                + {localization.Addnew[currentLang]}
              </Button>
            </div>
          )}
          {showAddAdress && (
            <>
              <p className="address-header-text">
                {localization.Youraddresslist[currentLang]}
              </p>
              <div className="address-list-wrapper">
                <Radio.Group
                  size="large"
                  // value={selectAddress}
                >
                  {addressList.length > 0 &&
                    addressList.map((address) => (
                      <Radio
                        value={address.id}
                        key={address.id}
                        onClick={this.onChangeAddress}
                      >
                        <div className="address-radio-holder">
                          <p className="address--name">{address.address}</p>
                          <div className="address-action-btn">
                            <img
                              src={edit}
                              alt="edit"
                              onClick={(e) =>
                                this.handelEditAddress(e, address)
                              }
                            />
                            <Divider type="vertical" />
                            <DeleteFilled
                              className="dlt-btn"
                              onClick={(e) => this.handelDltAddress(e, address)}
                            />
                            {/* <EditFilled onClick={(e) => this.handelEditAddress(e, address)} /> */}
                          </div>
                        </div>
                      </Radio>
                    ))}
                </Radio.Group>
              </div>
              {showAddBtn ? 
              <div className="add-address-btn-holder">
              <Button
              size="large" 
              type="primary"
              className="primary-light"
              // ghost
              onClick={() => this.setState({ showAddAdress: true, showAddBtn : false })}
              >
              + {localization.Addnewaddress[currentLang]}
            </Button> 
              </div> :
              <div className="add-address-wrapper">
                <h3 className="add-address-title">
                  {localization.Addnewaddress[currentLang]}
                </h3>

                <Form
                  name="basic"
                  ref={this.formAddressRef}
                  onFinish={this.onFinish}
                >
                  <Row gutter={20}>
                    <Col xs={24} lg={12} style={{ padding: 0 }}>
                      <Col xs={24}>
                        <Form.Item
                          name="City"
                          label={localization.City[currentLang]}
                          rules={[
                            {
                              required: true,
                              message: `${localization.Pleaseinput[currentLang]} ${localization.City[currentLang]}`,
                            },
                          ]}
                          className="input-holder group-floating-label no-icon"
                        >
                          {/* <Select placeholder={localization.City[currentLang]} size="large">
                  {statesList[0]?.cities?.map((state) => (
                    <Option value={state.id} key={state.id}>{state.name}</Option> 
                  ))}
                </Select> */}
                          <Input className="input-control" />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label={localization.Area[currentLang]}
                          name="area"
                          rules={[
                            {
                              required: true,
                              message: `${localization.Pleaseinput[currentLang]} ${localization.Area[currentLang]}`,
                            },
                          ]}
                          className="input-holder group-floating-label no-icon"
                        >
                          <Input className="input-control" />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label={localization.StreetNo[currentLang]}
                          name="streetName"
                          rules={[
                            {
                              required: true,
                              message: `${localization.Pleaseinput[currentLang]} ${localization.StreetNo[currentLang]}`,
                            },
                          ]}
                          className="input-holder group-floating-label no-icon"
                        >
                          <Input className="input-control" />
                        </Form.Item>
                      </Col>

                      <Col xs={24}>
                        <Form.Item
                          label={localization.BuildingName[currentLang]}
                          name="buildingNumber"
                          rules={[
                            {
                              required: true,
                              message: `${localization.Pleaseinput[currentLang]} ${localization.BuildingName[currentLang]}`,
                            },
                          ]}
                          className="input-holder group-floating-label no-icon"
                        >
                          <Input className="input-control" />
                        </Form.Item>
                      </Col>
                    </Col>

                    <Col sm={24} lg={12} style={{ padding: 0 }}>
                      <Col sm={24}>
                        <Row gutter={20}>
                          <Col xs={24} md={12}>
                            <Form.Item
                              label={localization.Floor[currentLang]}
                              name="floor"
                              rules={[
                                {
                                  required: true,
                                  message: `${localization.Pleaseinput[currentLang]} ${localization.Floor[currentLang]}`,
                                },
                              ]}
                              className="input-holder group-floating-label no-icon"
                            >
                              <Input className="input-control" type="number" />
                            </Form.Item>
                          </Col>

                          <Col xs={24} md={12}>
                            <Form.Item
                              label={localization.apartmentNumber[currentLang]}
                              name="apartment"
                              rules={[
                                {
                                  required: true,
                                  message: `${localization.Pleaseinput[currentLang]} ${localization.apartmentNumber[currentLang]}`,
                                },
                              ]}
                              className="input-holder group-floating-label no-icon"
                            >
                              <Input className="input-control" type="number" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>

                      <Col sm={24}>
                        <Form.Item
                          label={localization.Landmark[currentLang]}
                          name="landmark"
                          className="input-holder group-floating-label no-icon"
                          style={{ gridColumn: 'span 2' }}
                        >
                          <TextArea
                            className="input-control"
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Col>
                  </Row>
                  <div
                    className="btn-action-holder"
                    style={{ gridColumn: 'span 2' }}
                  >
                    {isEditId ? (
                      <Button
                        size="large"
                        style={{ marginTop: 16, width: 133 }}
                        type="primary"
                        htmlType="submit"
                        loading={btnLoading}
                      >
                        {localization.Update[currentLang]}
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        style={{ marginTop: 16, width: 133 }}
                        type="primary"
                        htmlType="submit"
                        loading={btnLoading}
                      >
                        {localization.Save[currentLang]}
                      </Button>
                    )}
                    <Button
                      size="large"
                      style={{ width: 142 }}
                      type="primary"
                      className="primary-light ml-20"
                      // ghost
                      onClick={ () => {  
                        if(this.props.ispopup){
                          this.props.closeModal();
                        }
                        if(isEditId){
                          this.setState({isEditId : false})
                          this.formAddressRef.current.resetFields();
                        }
                      }}
                    >
                      {localization.Cancel[currentLang]}
                    </Button>
                  </div>
                </Form>
              </div>}
            </>
          )}
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
  userAddress: state.user.userAdress,
});

export default connect(mapStateToProps)(MyAddresses);
