import React, { Component } from 'react'
import { Button, Form, Input, Select, Modal, message, Skeleton, Spin } from "antd";
import { HeartOutlined } from "@ant-design/icons";

import { Link } from 'react-router-dom';
import "./MyFavorite.scss";
import { fetchFavoriteProducts } from '../../network/profile.network';
import ProductCard from '../ProductCard/ProductCard';
import no_fav from "../../assets/img/no_fav.svg"
import { Link as I18Link } from 'react-router-i18n';

export default class MyFavorite extends Component {

  state = { 
    isLoading: true,
    favoriteList : [],
  };

  componentDidMount() {
    fetchFavoriteProducts((res) => {
      this.setState({isLoading  :true})
      this.setState({favoriteList : res.data.data.favoriteProducts, isLoading  :false})
  }, (error) => {
      this.setState({isLoading  :false})
  })
}

removeItemFromfav = (productDltId) => {
  // const filterCartItems = favoriteList.filter(item => item.id !== productDltId);
  // this.setState({favoriteList : filterCartItems})  
  fetchFavoriteProducts((res) => {
    this.setState({isLoading  :true})
    this.setState({favoriteList : res.data.data.favoriteProducts, isLoading  :false})
}, (error) => {
    this.setState({isLoading  :false})
})
}
  

  render() {
    const { isLoading, favoriteList } = this.state;
    const { localization, currentLang } = this.props;
    return (
      <Spin spinning={isLoading} >  
      <div className="favorite-tab-wrapper">
        {favoriteList.length < 1 ?
            <div className="no-favorite-holder">
              <img src={no_fav} alt="" />
            {/* <HeartOutlined /> */}
            <p>{localization.Youdonthavefavorite[currentLang]}</p>
            <Button
                size="large"
                style={{ width: 240 }}  
                type="primary"
                className="primary-light"
                ghost
            >
              <I18Link to={{ pathname: '/' }}>
              {localization.ShopAddproducts[currentLang]}
              </I18Link>
            </Button> 
            </div> : 
            <div className="favorite-list-wrapper"> 
              <h5 className="favorite-list-title">{localization.Yourfavoritelist[currentLang]}</h5>
              <div className="favorite-list-content">
            {isLoading ? (
                <>
                    <Skeleton avatar title={false} active></Skeleton>
                    <Skeleton avatar title={false} active></Skeleton>
                    <Skeleton avatar title={false} active></Skeleton>
                </>
            ) : (
              favoriteList?.map((product, idx) => (  
                <ProductCard 
                key={idx} 
                showCartCard 
                removeItemFromfav={this.removeItemFromfav}
                showFavCard
                card={product} />
              ))
            )}
              </div>
            </div>
          } 
      </div>
      </Spin>
    )
  }
}
