import React, { Component } from "react";
import ProductCard from "../ProductCard/ProductCard";
import "./DiscountSection.scss";
import axios from "axios";
import { Skeleton } from "antd";
export default class DiscountSection extends Component {
  state = {
    
  };

  render() {
    const { discoundList, title, isSingle } = this.props;
    return (
      <div className="discount-section-wrapper">
        <div className="container">
          <h4 className="section-title">{title}</h4>
          <div className="discount-section-content"> 
            
            {discoundList.length > 0 ? discoundList.map((card) => ( 
              <ProductCard isSingle={isSingle} card={card} />  
            )) : [0,1,3,4].map(skl => <Skeleton key={skl} avatar active paragraph={{ rows: 4 }} />)}
          </div>
        </div>
      </div>
    );
  }
}
