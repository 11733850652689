import React from "react";
import ReactPixel from "react-facebook-pixel";
function FaceBookPixelCart(props) {
  const { handelTotalPrice, cartItems } = props;
  React.useEffect(() => {
   
    ReactPixel.track("AddToCart", {
      contents: [
        ...cartItems.map(({ inCart: { itemId, quantity } }) => ({
          id: itemId,
          quantity,
        })),
      ],
      content_type: "product",
      value: handelTotalPrice(),
      currency: "EGP",
    });
  });
  return <></>;
}

export default FaceBookPixelCart;
