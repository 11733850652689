import { PRODUCTS_LIST } from "./products.type";

const INITIAL_STATE = {
  productsList: [],
};
const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PRODUCTS_LIST:
      return { 
        ...state,
        productsList: action.payload, 
      };
    default:
      return state;
  }
};


export default productsReducer;
