import React, { Component } from "react";
import "./ResNavBar.scss";
import {
  ShoppingCartOutlined,
  UserOutlined,
  HomeOutlined,
  HeartFilled,
  PercentageOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import localization from '../../Localization/Localization.json'
import { NavLink } from "react-router-dom";
import { Badge } from "antd";
import { connect } from "react-redux";
class ResNavBar extends Component {
  render() {
      const {currentLang, cartItems, currentUser} = this.props;
    return (
      <div className="res-bottom-nav">
        {/* <a
        exact 
        strict 
          activeStyle={{
            fontWeight: "bold",
            color: "#6cc04a",
          }}
          href="/cart"
        >
            <FileTextOutlined /> 
          {localization.magazine[currentLang]} 
        </a> */}
        <NavLink
        exact 
        strict 
          activeStyle={{
            fontWeight: "bold",
            color: "#6cc04a",
          }}
          to="/deals"
        >
          <PercentageOutlined />
          {localization.Deals[currentLang]} 
        </NavLink>
        <NavLink
        exact 
        strict 
          activeStyle={{
            fontWeight: "bold",
            color: "#6cc04a",
          }}
          to="/cart"
        >
          <Badge count={cartItems.length} size="small"> 
            <ShoppingCartOutlined />
        </Badge>
          {localization.cart[currentLang]} 
        </NavLink>
        <NavLink
        exact 
        strict 
          activeStyle={{
            fontWeight: "bold",
            color: "#6cc04a",
          }}
          to="/"
        >
          <HomeOutlined />
          {localization.home[currentLang]} 
        </NavLink>
        <NavLink
          activeStyle={{
            fontWeight: "bold", 
            color: "#6cc04a",
          }}
          to="/profile/5/Favourite" 
        >
          <HeartFilled />
          {localization.Favorite[currentLang]}
        </NavLink>
        <NavLink
        exact 
        strict 
          activeStyle={{
            fontWeight: "bold",
            color: "#6cc04a",
          }}
          to="/profile"
        >
          <UserOutlined />
          {currentUser && localization.Profile[currentLang]}
          {!currentUser && localization.Login[currentLang]}
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    cartItems: state.cart.cartItems,
    currentLang : state.user.currentLang,
    currentUser : state.user.currentUser,
}) 
  
export default connect(mapStateToProps)(ResNavBar); 
