import React, { Component } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import {
  HeartFilled,
  EnvironmentOutlined,
  ShoppingCartOutlined,
  CreditCardOutlined,
  UserOutlined,
  ImportOutlined,
} from '@ant-design/icons';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './Profile.scss';
import { Link, withRouter } from 'react-router-dom';
import { Link as I18Link } from 'react-router-i18n';
import localization from '../../Localization/Localization.json';
import { connect } from 'react-redux';
import PersonalData from '../../components/PersonalData/PersonalData';
import MyAddresses from '../../components/MyAddresses/MyAddresses';
import MyFavorite from '../../components/MyFavorite/MyFavorite';
import {
  setBranchUser,
  setCurrentUser,
  setPaymentUser,
} from '../../redux/user/user.action';
import { clearCart } from '../../redux/cart/cart.action';
import ResNavBar from '../../components/ResNavBar/ResNavBar';
import MyOrder from '../../components/MyOrder/MyOrder';
import {Helmet} from "react-helmet";
import meta from '../../Localization/meta.json';
const { TabPane } = Tabs;

class Profile extends Component {
  state = {
    defaultActiveKey: '1',
    breadcrumbList: [],
    showOrderList: true,
  };

  componentDidMount() {
    const { match, location, currentUser } = this.props;
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500);
    if (!currentUser) {
      this.props.history.push(`/${this.props.currentLang}/login`);
    }
    if (match.params.id) {
      this.setState({ defaultActiveKey: '' + match.params.id });
      this.handelActiveTab('' + match.params.id);
    }
    this.setState({ windowWidth: window.innerWidth });
  }

  handelActiveTab = (activeKey) => {
    let activeTabName = '';
    const { currentLang } = this.props;
    switch (activeKey) {
      case '1':
        activeTabName = localization.Account[currentLang];
        this.props.history.replace({
          pathname: `/${currentLang}/profile/${1}/${activeTabName}`
        });
        break;
      case '2':
        activeTabName = localization.MyAddresses[currentLang];
        this.props.history.replace({
          pathname: `/${currentLang}/profile/${2}/${localization.Addresses[currentLang]}`
        });
        break;
      case '3':
        activeTabName = localization.Myordrers[currentLang];
        this.props.history.replace({
          pathname: `/${currentLang}/profile/${3}/${localization.ordrers[currentLang]}`
        });
        this.props.history.replace(
        );
        break;
      case '4':
        activeTabName = localization.Payment[currentLang];
        this.props.history.replace({ pathname : `/${currentLang}/profile/${4}/${activeTabName}`});
        break;
      case '5':
        activeTabName = localization.Favorite[currentLang];
        this.props.history.replace({ pathname : `/${currentLang}/profile/${5}/${activeTabName}`});
        break;

      default:
        activeTabName = localization.Account[currentLang];
        break;
    }
    this.setState({ breadcrumbList: [activeTabName] });
  };

  render() {
    const { currentLang } = this.props;
    const { defaultActiveKey, windowWidth, breadcrumbList, showOrderList } =
      this.state;
    return (
      <div className="profile-page-wrapper">
        <Helmet>
            <title>{meta.KhairZamanProfile[currentLang]}</title>
        </Helmet>
        <Header />
        <div className="container">
          <div className="profile-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: '/' }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              {breadcrumbList.map((bread) => (
                <Breadcrumb.Item>{bread}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
            <Tabs
              activeKey={`${defaultActiveKey}`}
              tabPosition={windowWidth > 992 ? 'left' : 'top'}
              onChange={(key) => {
                if (key === '3') {
                  this.setState({ showOrderList: true });
                }
                if (key !== '6') {
                  this.setState({ defaultActiveKey: key });
                  this.handelActiveTab(key);
                } else {
                  localStorage.removeItem('token');
                  this.props.dispatch(setCurrentUser(null));
                  this.props.dispatch(setPaymentUser(null));
                  this.props.dispatch(setBranchUser(null));
                  this.props.dispatch(clearCart());
                  setTimeout(() => {
                    this.props.history.push(`/${this.props.currentLang}/`);
                  }, 300);
                }
              }}
            >
              <TabPane
                tab={
                  <span>
                    <UserOutlined />
                    {localization.Account[currentLang]}
                  </span>
                }
                key="1"
              >
                <PersonalData
                  localization={localization}
                  currentLang={currentLang}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <EnvironmentOutlined />
                    {localization.MyAddresses[currentLang]}
                  </span>
                }
                key="2"
              >
                <MyAddresses
                  localization={localization}
                  currentLang={currentLang}
                />
              </TabPane>
              <TabPane
                tab={
                  <span>
                    <ShoppingCartOutlined />
                    {localization.Myordrers[currentLang]}
                  </span>
                }
                key="3"
              >
                <MyOrder
                  localization={localization}
                  currentLang={currentLang}
                />
              </TabPane>
              {/* <TabPane
                tab={
                  <span>
                    <CreditCardOutlined />
                    {localization.Payment[currentLang]}  
                  </span>
                }
                key="4"
              >
                {localization.Payment[currentLang]}    
              </TabPane> */}
              <TabPane
                tab={
                  <span>
                    <HeartFilled />
                    {localization.Favoritelist[currentLang]}
                  </span>
                }
                key="5"
              >
                <MyFavorite
                  localization={localization}
                  currentLang={currentLang}
                />
              </TabPane>
              <TabPane
                // className="logout-tab"
                tab={
                  <span>
                    <ImportOutlined />
                    {localization.Logout[currentLang]}
                  </span>
                }
                key="6"
              >
                {/* <MyFavorite localization={localization} currentLang={currentLang} /> */}
              </TabPane>
            </Tabs>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const ProfileWithRouter = withRouter(Profile);
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
  currentUser: state.user.currentUser,
});
export default connect(mapStateToProps)(ProfileWithRouter);
