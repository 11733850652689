import { message } from "antd";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY; 

export const getApi = (endPoint, onSucess, onFail) => {
  const headers = {
  "Content-Type": "application/json",
  "content-language": `${localStorage?.getItem("lang") || "en"}`,  
};
if(localStorage.getItem("token")){
  headers.Authorization = `Bearer ${localStorage?.getItem("token")}`
}
if(localStorage.getItem("deviceId")){
  // if(!!!localStorage.getItem("token")){
    headers.deviceId = localStorage.getItem("deviceId") 
  // }
}
axios({ 
  method: 'get',
  url: `${url}${endPoint}`,
  headers,
  })
  .then((response) => {
      if (response.data.validation.length > 0) { 
          response.data.validation.forEach(element => {
              // message.error(element); 
          });
      }
      return onSucess(response);
  }) 
  .catch(err => onFail(err)); 
}