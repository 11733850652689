import { message, Radio, Space } from "antd";
import Modal from "antd/lib/modal/Modal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { fetchReasonsList, SelectPayment } from "../../network/cart.network";
import { CancelOrder } from "../../network/profile.network";
import { setPaymentUser } from "../../redux/user/user.action";

class CancellationReasons extends Component {
  state = {
    showModal: false,
    confirmLoading: false,
    value: 0,
    reasonsList : []
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    this.fetchReasonsList(); 
  }
  

  fetchReasonsList() {
    fetchReasonsList((res) => {
      this.setState({reasonsList : res.data.data.reasons})
    }, (error) => {
      console.log(error); 
    })
  }
  

  componentDidUpdate(prevProps) {
    if (this.props.showModalVisible !== this.state.showModal) {
      this.setState({ showModal: this.props.showModalVisible });
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handelSelectReason = () => {
    const { value } = this.state;
    const { orderId, fromOrderList } = this.props;
    this.setState({ confirmLoading: true });
    let body = new FormData();
    body.append('reason', value);
    CancelOrder(
      orderId,
      body,
      (res) => {
        this.setState({ confirmLoading: false });
        if (res.data.code === 200) {
          this.setState({ confirmLoading: false,  showModal : false}); 
          message.success(res.data.message);
          if(fromOrderList){
            this.props.closeModal();
          }else{
            this.props.history.push(`/${this.props.currentLang}/profile/3/orders`);
          }
        }
      },
      (error) => {
        this.setState({ confirmLoading: false });
        console.log(error);
      }
    );
  };

  render() {
    const { showModal, value, confirmLoading, reasonsList } = this.state;
    const { currentLang, localization } = this.props;
    return (
      <div className="pay-modal-wrapper">
        <Modal
          wrapClassName="pay-modal"
          onCancel={this.props.closeModal}
          okText={localization.Save[currentLang]} 
          cancelText={localization.Cancel[currentLang]}
          onOk={this.handelSelectReason}
          confirmLoading={confirmLoading}
          visible={showModal}
        >
          <Radio.Group onChange={this.onChange} value={value}>
            <Space direction="vertical" size="middle"> 
              {reasonsList.map(reason => 
                  <Radio value={reason.id} key={reason.id}>{reason.name}</Radio>
              )}
            </Space>
          </Radio.Group>
        </Modal>
      </div>
    );
  }
}

const CancellationReasonsWithRouter = withRouter(CancellationReasons); 
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(CancellationReasonsWithRouter); 
