import { message } from "antd";
import axios from "axios";
const url = process.env.REACT_APP_API_KEY;

export const applyPromoCode = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/promoCode/add`,
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

export const applyRemovePromoCode = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/promoCode/remove`,
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const GenerateOrderSession = (body, onSucess, onFail) => {
    const headers = {
    "Content-Type": "application/json",
    "content-language": `${localStorage.getItem("lang") || "en"}`,  
  };
  if(localStorage.getItem("token")){
    headers.Authorization = `Bearer ${localStorage.getItem("token")}`
  }
  if(localStorage.getItem("deviceId")){
    if(!!!localStorage.getItem("token")){
      headers.deviceId = localStorage.getItem("deviceId") 
    }
  }
  axios({
  method: "post",
  url: `${url}cart/generate/order/session`,
  data: body,
  headers, 
})
  .then((response) => {
    if (response.data.validation.length > 0) {
      response.data.validation.forEach((element) => {
        message.error(element);
      });
    }
    return onSucess(response);
  })
  .catch((err) => onFail(err));
};

export const applycomment = (body, onSucess, onFail) => {
        const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/comment`,
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const SelectSubscription = (body, onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/subscription/update`,
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const cancelCartSubscription = (onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "get", 
      url: `${url}cart/subscription/cancel`, 
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const OrderSubscription = (body, onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}order/subscribe`, 
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const OrderUnSubscription = (body, onSucess, onFail) => { 
    const headers = {
      "Content-Type": "application/json", 
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post", 
      url: `${url}order/unsubscribe`,  
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const PlaceOrder = (body, onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/order`, 
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const SelectPayment = (body, onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}cart/payment/select`,  
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };

  export const SelectOrderAlternatives = (body, id, onSucess, onFail) => {
    const headers = {
      "Content-Type": "application/json",
      "content-language": `${localStorage.getItem("lang") || "en"}`,  
    };
    if(localStorage.getItem("token")){
      headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if(localStorage.getItem("deviceId")){
      if(!!!localStorage.getItem("token")){
        headers.deviceId = localStorage.getItem("deviceId") 
      }
    }
    axios({
      method: "post",
      url: `${url}orders/${id}/alternatives`,  
      data: body,
      headers,
    })
      .then((response) => {
        if (response.data.validation.length > 0) {
          response.data.validation.forEach((element) => {
            message.error(element);
          });
        }
        return onSucess(response);
      })
      .catch((err) => onFail(err));
  };


  
export const fetchPaymentList = (onSucess, onFail) => {
  const headers = {
"Content-Type": "application/json",
"content-language": `${localStorage.getItem("lang") || "en"}`,  
};
if(localStorage.getItem("token")){
headers.Authorization = `Bearer ${localStorage.getItem("token")}`
}
if(localStorage.getItem("deviceId")){
if(!!!localStorage.getItem("token")){
  headers.deviceId = localStorage.getItem("deviceId") 
}
}
axios({ 
  method: 'get',
  url: `${url}cart/payment/types`, 
  headers,
  })
  .then((response) => {
      if (response.data.validation.length > 0) { 
          response.data.validation.forEach(element => {
              message.error(element); 
          });
      }
      return onSucess(response);
  }) 
  .catch(err => onFail(err)); 
}
  
export const fetchReasonsList = (onSucess, onFail) => {
  const headers = {
"Content-Type": "application/json",
"content-language": `${localStorage.getItem("lang") || "en"}`,  
};
if(localStorage.getItem("token")){
headers.Authorization = `Bearer ${localStorage.getItem("token")}`
}
if(localStorage.getItem("deviceId")){
if(!!!localStorage.getItem("token")){
  headers.deviceId = localStorage.getItem("deviceId") 
}
}
axios({ 
  method: 'get',
  url: `${url}list/cancellation/reasons`, 
  headers,
  })
  .then((response) => {
      if (response.data.validation.length > 0) { 
          response.data.validation.forEach(element => {
              message.error(element); 
          });
      }
      return onSucess(response);
  }) 
  .catch(err => onFail(err)); 
}
export const ShowOrderAlternatives = (id, onSucess, onFail) => {
  const headers = {
"Content-Type": "application/json",
"content-language": `${localStorage.getItem("lang") || "en"}`,  
};
if(localStorage.getItem("token")){
headers.Authorization = `Bearer ${localStorage.getItem("token")}`
}
if(localStorage.getItem("deviceId")){
if(!!!localStorage.getItem("token")){
  headers.deviceId = localStorage.getItem("deviceId") 
}
}
axios({ 
  method: 'get',
  url: `${url}orders/${id}/alternatives`, 
  headers,
  })
  .then((response) => {
      if (response.data.validation.length > 0) { 
          response.data.validation.forEach(element => {
              message.error(element); 
          });
      }
      return onSucess(response);
  }) 
  .catch(err => onFail(err)); 
}
  
export const EmptyCart = (onSucess, onFail) => {
  const headers = {
"Content-Type": "application/json",
"content-language": `${localStorage.getItem("lang") || "en"}`,  
};
if(localStorage.getItem("token")){
headers.Authorization = `Bearer ${localStorage.getItem("token")}`
}
if(localStorage.getItem("deviceId")){
if(!!!localStorage.getItem("token")){
  headers.deviceId = localStorage.getItem("deviceId") 
}
}
axios({ 
  method: 'post',
  url: `${url}cart/empty`,
  headers,
  })
  .then((response) => {
      if (response.data.validation.length > 0) { 
          response.data.validation.forEach(element => {
              message.error(element); 
          });
      }
      return onSucess(response);
  }) 
  .catch(err => onFail(err)); 
}