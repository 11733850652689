import {
  SET_CURRENT_USER,
  SET_AREAS,
  SET_LANG,
  UPDATE_CURRENT_USER,
  SET_ADDRESS,
  SET_BRANCH,
  SET_PAYMENT,
  SHOW_BRANDS,
  SET_CATEGORIES,
  SET_USER_ADDRESS,
} from './user.type';

const INITIAL_STATE = {
  currentUser: null,
  currentAddress: null,
  currentBranch: null,
  currentPayment: null,
  userAdress: null,
  categories: null,
  brandList: [],
  areas: null,
};
const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SHOW_BRANDS:
      return {
        ...state,
        brandList: action.payload,
      };
    case SET_AREAS:
      return {
        ...state,
        areas: action.payload,
      };
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    case SET_LANG:
      return {
        ...state,
        currentLang: action.payload,
      };
    case SET_ADDRESS:
      return {
        ...state,
        currentAddress: action.payload,
      };

    case SET_USER_ADDRESS:
      return {
        ...state,
        userAdress: action.payload,
      };

    case SET_BRANCH:
      return {
        ...state,
        currentBranch: action.payload,
      };
    case SET_PAYMENT:
      return {
        ...state,
        currentPayment: action.payload,
      };

    default:
      return state;
  }
};

export default userReducer;
