import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link as I18Link } from "react-router-i18n";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import ResNavBar from "../../components/ResNavBar/ResNavBar";
import localization from "../../Localization/Localization.json";
import faqLocal from "../../Localization/faq.json";
import { Breadcrumb, Collapse, Skeleton } from "antd";
import "./FAQS.scss";
import { CaretRightOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import { faqLocalC } from "../../constants/constants";
import {Helmet} from "react-helmet";
 import meta from '../../Localization/meta.json';
const { Panel } = Collapse;

class FAQS extends Component {
  componentDidMount() {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }

  render() {
    const { currentLang } = this.props;
    return (
      <div className="faq-page-wrapper">
         <Helmet>
            <title>{meta.KhairZamanFAQS[currentLang]}</title>
        </Helmet>
        <Header /> 
        <div className="container">
          {/* <div className="faq-hero">
          </div> */}
          <div className="faq-content">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <I18Link to={{ pathname: "/" }}>
                  {localization.home[currentLang]}
                </I18Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {localization.FAQs[currentLang]}
              </Breadcrumb.Item>
            </Breadcrumb>
            <div className="faq-content-holder">
              <h2>{faqLocal.General[currentLang]}</h2>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header={faqLocal.WhatisKheiZaman[currentLang]}
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <p>{parse(faqLocal.WhatisKheiZamanInfo[currentLang])}</p>
                </Panel>
                <Panel
                  header={
                    faqLocal.HowcanImakeanorderonKheirZamanforthefirsttime[
                      currentLang
                    ]
                  }
                  key="2"
                  className="site-collapse-custom-panel"
                >
                  <p>
                    {
                      faqLocal
                        .HowcanImakeanorderonKheirZamanforthefirsttimeInfo1[
                        currentLang
                      ]
                    }
                  </p>
                  <p>
                    {
                      faqLocal
                        .HowcanImakeanorderonKheirZamanforthefirsttimeInfo2[
                        currentLang
                      ]
                    }
                  </p>
                </Panel> 
                
                <Panel
                  header={faqLocal.Whatkindofproductsdoyouhave[currentLang]}
                  key="3"
                  className="site-collapse-custom-panel"
                >
                  <p>{parse(faqLocal.WhatkindofproductsdoyouhaveInfo[currentLang])}</p>
                </Panel>
                <Panel
                  header={faqLocal.CantfindproductwhatcanIdo[currentLang]}
                  key="4"
                  className="site-collapse-custom-panel"
                >
                  <p>{faqLocal.CantfindproductwhatcanIdoInfo[currentLang]}</p>
                </Panel>
                <Panel
                  header={faqLocal.HowcanIpayformyorder[currentLang]}
                  key="5"
                  className="site-collapse-custom-panel"
                >
                  {parse(faqLocalC[0][currentLang])}
                </Panel>
                <Panel
                  header={parse(faqLocal.Ifpayonlineanditemsaremissinghowwillberefunded[currentLang])}
                  key="6"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {parse(faqLocal.IfpayonlineanditemsaremissinghowwillberefundedInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={parse(faqLocal.Howcansendyoufeedback[currentLang])}
                  key="7"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {parse(faqLocal.HowcansendyoufeedbackInfo1[currentLang])}
                  </p>
                  <p>
                  {parse(faqLocal.HowcansendyoufeedbackInfo2[currentLang])}
                  </p>
                </Panel>
              </Collapse>
              <h2>{faqLocal.Delivery[currentLang]}</h2>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
              >
                <Panel
                  header={faqLocal.Whichareasdoyoudeliverto[currentLang]}
                  key="1"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {faqLocal.WhichareasdoyoudelivertoInfo[currentLang]}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.Icantfindareawhatdo[currentLang]}
                  key="2"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {parse(faqLocal.IcantfindareawhatdoInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.minimumorder[currentLang]}
                  key="3"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {faqLocal.minimumorderInfo[currentLang]}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.delivery24[currentLang]}
                  key="4"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {faqLocal.delivery24Info[currentLang]}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.deliveryaddress[currentLang]}
                  key="5"
                  className="site-collapse-custom-panel"
                >
                  <p>
                  {faqLocal.deliveryaddressInfo[currentLang]}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.receiveorder[currentLang]}
                  key="6"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.receiveorderInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.orderedoutofstock[currentLang]}
                  key="7"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.orderedoutofstockInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.estimatedarrivaltime[currentLang]}
                  key="8"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.estimatedarrivaltimeInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.nothomeTime[currentLang]}
                  key="9"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.nothomeTimeInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.trackstatus[currentLang]}
                  key="10"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.trackstatusInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.changesAfterOrder[currentLang]}
                  key="11"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.changesAfterOrderInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.changedeliveryaddress[currentLang]}
                  key="12"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.changedeliveryaddressInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.scheduleorder[currentLang]}
                  key="13"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.scheduleorderInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.repeatorder[currentLang]}
                  key="14"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.repeatorderInfo[currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.Whatarefavorites[currentLang]}
                  key="15"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocal.WhatarefavoritesInfo[currentLang])}
                  </p>
                </Panel>
              </Collapse>
              <h2>{faqLocal.Cancelationrefundpolicy[currentLang]}</h2>
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                  )}
                  className="site-collapse-custom-collapse"
                  >
                <Panel
                  header={faqLocal.Whenhowcancancelmyorder[currentLang]}
                  key="16"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {parse(faqLocalC[1][currentLang])}
                  </p>
                </Panel>
                <Panel
                  header={faqLocal.productincorrect[currentLang]}
                  key="17"
                  className="site-collapse-custom-panel"
                >
                  <p> 
                  {faqLocal.productincorrectInfo[currentLang]} 
                  </p>
                </Panel>
            </Collapse>
            </div>
          </div>
        </div>
        <Footer />
        <ResNavBar />
      </div>
    );
  }
}

const FAQSWithRouter = withRouter(FAQS);
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});
export default connect(mapStateToProps)(FAQSWithRouter);
